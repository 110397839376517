import { Observable } from 'rxjs';
import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { FinancialsRepository } from 'app/repositories/financials.repository';
import { useCallback, useMemo } from 'react';
import { PaymentMethodType, PaymentOptionType } from 'shared/enums/settings/finance.enum';

export const usePaymentOption = (carrierCode: string) => {
    const financialRepo = useRepository(FinancialsRepository);
    const { financialSettingsSection } = useFlags();

    const [paymentOption, forceRefreshCallback] = useAjaxResponse(() => {
        if (!financialSettingsSection || !carrierCode) {
            return Observable.of<PaymentOption>(null);
        }

        return financialRepo
            .getCarrierPaymentOption(carrierCode)
            .catch(_ => Observable.of<PaymentOption>(null));
    }, [carrierCode], { silent: true });

    const isEligibleForQuickPay = (paymentOption?.type === PaymentOptionType.StandardPay) &&
        (paymentOption?.paymentMethod === PaymentMethodType.EFT);

    return {
        paymentOption,
        isEligibleForQuickPay,
        forceRefreshCallback
    };
}

export const useGetCarrierQuickPayRate = (carrierCode: string) => {
    const repo = useRepository(FinancialsRepository);

    const financialsRequestFactory = useCallback(
        () => repo.getCarrierQuickPayRate(carrierCode),
        [repo]
    );
    const [carrierQPRate] = useAjaxResponse(financialsRequestFactory, []);

    return useMemo(() => carrierQPRate, [carrierQPRate]);
}
