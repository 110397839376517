import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { useSnackbar } from '@chr/eds-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Box, Button, ButtonGroup, CircularProgress, InputLabel, Typography } from '@mui/material';
import { Select, TextInput } from '@chr/eds-react';

import { putUserProfile } from 'api/userManagement';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';
import { PhoneNumberType, RegistrationPhoneNumberInput } from 'features/registration';
import * as Styles from 'features/set-up-user/styles';
import { useSelector } from '@app/hooks/store/use-selector.hook';

// Validation schema
const validationSchema = t =>
 Yup.object().shape({
  country: Yup.string().required(t('COUNTRY_IS_REQUIRED')),
  firstName: Yup.string().required(t('FIRST_NAME_REQUIRED')),
  lastName: Yup.string().required(t('LAST_NAME_REQUIRED')),
  culture: Yup.string().required(t('CULTURE_IS_REQUIRED')),
  phone: Yup.string()
   .nullable()
   .test('is-valid-phone', t('PHONE_NUMBER_IS_INVALID'), value => {
    if (!value) return false;
    try {
     const phoneNumber = parsePhoneNumber(value);
     return phoneNumber.isValid();
    } catch {
     return false;
    }
   }),
 });

export const SetUpUser = () => {
 const { t } = useTranslation();
 const dispatch = useDispatch();
 const snackbar = useSnackbar();
 const { authState } = useOktaAuth();
 const { enableUserManagement } = useFlags();

 const refDataRepo = new ReferenceDataRepository();

 const [isLoading, setIsLoading] = useState(false);
 const [countries, setCountries] = useState([]);

 const { userProfile, email } =
  useSelector(state => ({
   userProfile: state.userManagement.userProfile,
   email: state.okta.preferredEmailAddress,
  })) || {};

 const user = useSelector(state => state.auth.user);
 const userId = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims?.userId;

 const { firstName, lastName, country, culture, phoneType, phone } = userProfile || {};

 useEffect(() => {
  const fetchReferenceData = async () => {
   const countriesData = await refDataRepo.getGlobalizationLocales().toPromise();
   setCountries(countriesData);
  };
  fetchReferenceData();
 }, []);

 const handleSubmit = async values => {
  setIsLoading(true);
  const { defaultMembershipId, ...requestBody } = values;

  try {
   await putUserProfile(userId, requestBody);

   snackbar.enqueueSnackbar({
    id: 'profile-updated-success',
    message: t('PROFILE_SETTINGS_SAVED'),
    severity: 'success',
   });
  } catch {
   snackbar.enqueueSnackbar({
    id: 'update-profile-failure',
    message: t('FALLBACK_SOMETHING_WENT_WRONG'),
    severity: 'error',
   });
  } finally {
   setIsLoading(false);
  }
 };

 if (!authState?.isAuthenticated || !enableUserManagement) return null;

 return (
  <Formik
   initialValues={{
    country: country || 'US',
    firstName: firstName || '',
    lastName: lastName || '',
    culture: culture || 'en-US',
    phoneType: phoneType || PhoneNumberType.MOBILE,
    phone: phone || '',
   }}
   validationSchema={validationSchema(t)}
   onSubmit={handleSubmit}
   validateOnMount
  >
   {({ values, setValues, setFieldValue, handleSubmit, errors, isValid }) => {
    const availableLocales = countries.find(c => c.code === values.country)?.locales;
    const countryMenuItems = countries.map(c => ({ label: c.name, value: c.code }));
    const localeMenuItems = availableLocales ? availableLocales.map(locale => ({ label: locale.displayName, value: locale.locale })) : [];

    return (
     <Form>
      <Box sx={Styles.Container}>
       <Box sx={Styles.Card}>
        <Box sx={Styles.CardForm}>
         <Typography sx={Styles.Header} variant="h3">
          <div>{t('WELCOME_TO_NEW_USER', { email })}</div>
          <div>{user?.carrierName}</div>
         </Typography>
         <Typography variant="body1">{t('PLEASE_REVIEW_OR_ENTER')}</Typography>

         <Select
          label={t('COUNTRY')}
          menuItems={countryMenuItems}
          onChange={e => setValues({ ...values, country: e.target.value })}
          value={values.country}
          fullWidth
          error={!!errors.country}
          helperText={errors.country as string}
         />

         <Select
          label={t('LANGUAGE')}
          menuItems={localeMenuItems}
          onChange={e => setFieldValue('culture', e.target.value)}
          value={values.culture}
          disabled={availableLocales?.length === 0}
          fullWidth
          error={!!errors.culture}
          helperText={errors.culture as string}
         />

         <TextInput
          label={t('FIRST_NAME')}
          value={values.firstName}
          onChange={e => setFieldValue('firstName', e.target.value)}
          error={!!errors.firstName}
          helperText={errors.firstName as string}
          fullWidth
         />

         <TextInput
          label={t('LAST_NAME')}
          value={values.lastName}
          onChange={e => setFieldValue('lastName', e.target.value)}
          error={!!errors.lastName}
          helperText={errors.lastName as string}
          fullWidth
         />

         <Box sx={{ width: '100%' }}>
          <InputLabel sx={Styles.PhoneNumberInputLabel}>{t('PHONE_NUMBER')}</InputLabel>
          <ButtonGroup fullWidth>
           <Button
            sx={values.phoneType === PhoneNumberType.MOBILE ? Styles.SelectedButton : Styles.FormButton}
            onClick={() => setFieldValue('phoneType', PhoneNumberType.MOBILE)}
           >
            {t('MOBILE')}
           </Button>
           <Button
            sx={values.phoneType === PhoneNumberType.BUSINESS ? Styles.SelectedButton : Styles.FormButton}
            onClick={() => setFieldValue('phoneType', PhoneNumberType.BUSINESS)}
           >
            {t('BUSINESS')}
           </Button>
          </ButtonGroup>
         </Box>

         <Box sx={{ width: '100%' }}>
          <RegistrationPhoneNumberInput
           phoneNumber={values.phone}
           handlePhoneNumber={e => setFieldValue('phone', e)}
           error={errors.phone as string}
          />
         </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
         <Button
          onClick={() => handleSubmit()}
          variant="contained"
          startIcon={isLoading && <CircularProgress sx={Styles.CircularProgressIcon} size={16} />}
          disabled={!isValid || isLoading}
          sx={{ width: '100%', height: '56px' }}
          data-testid="save-btn"
         >
          {t('SAVE')}
         </Button>
        </Box>
       </Box>
      </Box>
     </Form>
    );
   }}
  </Formik>
 );
};
