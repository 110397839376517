import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { toggleMembershipDialog, setMembershipDialogView, CompanySelectionDialogViews } from 'features/permissions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useUserAndCarrierDataContext } from 'features/userAndCarrierData';

import * as Styles from 'features/invitation/styles';

interface InvitationAcceptedProps {
 data: ValidateInvitationTokenResponse;
}

export const InvitationAccepted = ({ data }: InvitationAcceptedProps) => {
 const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
 const { t } = useTranslation();
 const dispatch = useDispatch();
 const { getUpdatedActiveMembership } = useUserAndCarrierDataContext();

 const setCompanyDialogOpen = () => {
  getUpdatedActiveMembership();
  dispatch(toggleMembershipDialog(true));
  dispatch(setMembershipDialogView(CompanySelectionDialogViews.INVITATION_ACCEPTED));
 };

 return (
  <Box sx={Styles.Container}>
   <Box sx={Styles.Card}>
    <Box sx={Styles.ImageWrapper}>
     <img src="assets/images/user-unknown-added.svg" width="104px" height="104px" />
    </Box>
    <Typography variant="heading100" sx={Styles.Header} textAlign="center">
     {t('WELCOME_TO_COMPANY', { company: data.companyName })}
    </Typography>
    <Typography variant="body100" sx={Styles.Paragraph} textAlign="center">
     {t('YOU_HAVE_BEEN_ADDED_TO_COMPANY', { company: data.companyName })}
    </Typography>
    <Typography variant="body100" sx={Styles.Paragraph} textAlign="center">
     {t(isAuthenticated ? 'YOU_NOW_HAVE_ACCESS' : 'LOGIN_WITH_YOUR_CREDENTIALS')}
    </Typography>
    <Box sx={Styles.Actions}>
     {isAuthenticated ? (
      <Button variant="contained" onClick={setCompanyDialogOpen}>
       {t('CONTINUE')}
      </Button>
     ) : (
      <Button variant="contained" sx={Styles.LinkButton} href="/login">
       {t('LOG_IN')}
      </Button>
     )}
    </Box>
   </Box>
  </Box>
 );
};
