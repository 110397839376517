import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { Grants } from 'features/security/grants';
import { isAuthorized, UserPermissions } from 'features/permissions';

export const useCarrierAdvantage = (): boolean => {
  const carrier = useStoredCarrierDetail();
  const user = useStoredUser();
  const hasPermission = isAuthorized([UserPermissions.LOAD_MANAGEMENT]);

  return (Grants.CarrierAdvantageGrant(user) || hasPermission) && !carrier.isEuropean();
};
