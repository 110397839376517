const getMockedResponse = (url: URL, config: RequestInit, mockedResponses: mockedResponse[]): mockedResponse => {
  if (!url || !config) {
    return null;
  }

  const mockedResponse = mockedResponses.find((mock) => {
    if (config.method.toUpperCase() === mock.method.toUpperCase() && url.pathname.startsWith(mock.path)) {
      return true;
    }
  });

  if (mockedResponse) {
    return mockedResponse;
  }

  return null;
}

const handleDelayedFunction = (response: mockedResponse) => {
  if (response?.delayedResponse?.func) {
    const timeout = response.delayedResponse.secondsOfDelay ?? 3;
    setTimeout(() => {
      response.delayedResponse.func();
    }, timeout * 1000)
  }
}

const getUrl = (url: URL | RequestInfo): URL => {
  if (!url) {
    return null;
  }

  if (typeof url === 'string') {
    return new URL(url);
  }

  return url as URL;
}

export const init = (mockedResponses: mockedResponse[]) => {
  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    let [resource, config] = args;
    const url = getUrl(resource);
    const mockedResponse = getMockedResponse(url, config, mockedResponses);
    const response = mockedResponse?.responseFunc(url, config);

    if (response) {
      handleDelayedFunction(mockedResponse);
      return response;
    }

    return await originalFetch(resource, config);
  };
}

export default {
  init
}