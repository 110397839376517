import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useIsPaymentsUser = (): boolean => {
  const { enablePaymentsPage } = useFlags();
  const user = useSelector(state => state.auth.user);
  const carrier = useSelector(state => state.auth.carrier);
  const hasArRights = (user?.roles?.AR || user?.roles?.ARAdmin) ?? false;
  return enablePaymentsPage && (carrier?.isNorthAmerican() ?? false) && hasArRights;
}
