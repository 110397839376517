import { useTranslation, Trans } from 'react-i18next';

import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { useOnAcknowledgeTerms } from 'shared/components/terms-and-conditions/hooks/use-on-acknowledge-terms.hook';

import './terms-validation-modal.component.scss';

export const TermsValidationModal = () => {
  const onAcknowledgeTerms = useOnAcknowledgeTerms();
  const { t } = useTranslation();

  return (
    <Modal
      disableClose
      name="accept-terms"
      footer={<Button btnPrimary resource="I_ACKNOWLEDGE" onClick={onAcknowledgeTerms} />}
    >
      <Trans
        i18nKey="I_HAVE_READ_THE_TERMS_AND_AGREE_TO_THE_0_1_AND_2"
        components={[
          <a href="https://www.chrobinson.com/en/us/Terms-Of-Use/" target="_blank">
            {t('TERMS_OF_USE')}
          </a>,
          <a href="https://www.chrobinson.com/en-us/privacy-notice/" target="_blank">
            {t('GLOBAL_PRIVACY_NOTICE')}
          </a>,
          <a href="https://www.chrobinson.com/your-privacy-rights" target="_blank">
            {t('YOUR_PRIVACY_RIGHTS')}
          </a>
        ]}
      />
    </Modal>
  );
};
