import { Container } from 'typedi';
import { Subject } from 'rxjs/Subject';
import { Store } from 'store/index';
import { refreshAvailableLoads } from 'store/epics/find-loads/find-loads-base.actions';

export interface InternalAPI {
  refreshLoads: () => void;
  refreshBINLoadsSubject: Subject<any>;
  sessionStorage: { [key: string]: string };
}

const API: InternalAPI = {
  refreshLoads: () => Container.get(Store).dispatch(refreshAvailableLoads()),
  refreshBINLoadsSubject: new Subject(),
  sessionStorage: null,
};

const INTERNAL_API_KEY = Container.get<string>('appConstants.internalAPIKey');

window[INTERNAL_API_KEY] = API; // public API entry point
Container.set('internalAPI', API);
