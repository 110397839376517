import React from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Translation } from 'shared/components/translation/translation.component';
import { Props as NavLinkProps } from './nav-item.component';
import { useAuthorization } from 'features/permissions';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useRouterLocation } from 'app/hooks/store/use-router-location.hook';

export const TendersNavItem: React.FC<NavLinkProps> = ({url, resourceKey}) => {
  const count = useSelector(state => state.tenderCount);
  const location = useRouterLocation();

  const isActive = useMemo(() => location.pathname.startsWith(url), [location, url]);

  if (!useAuthorization(url)) {
    return null;
  }

  {/* react-router-dom <NavLink /> is broken only calculating active state on initial mount. User <Link /> instead */}
  return (
    <Link to={url} className={isActive ? 'active' : ''}>
      <Translation resource={resourceKey}/>
      {Boolean(count) &&
      <span className="navbar-load-count">
              <span>{count}</span>
            </span>
      }
    </Link>
  );
};
