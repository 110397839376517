/* istanbul ignore file */
import { useEffect, useRef, useState, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import { getOktaConfig } from '@features/okta/config';
import { OktaWidgetStringOverrides } from '@features/okta/okta-widget-string-overrides';
import { JwtBanner } from '@features/okta/styles';
import { CarriersRepository } from '@app/repositories/carriers.repository';
import { processInvitation } from 'api/userManagement';
import { AppRoute } from 'app/routesEnum';

import { AccessToken, IDToken, RefreshToken } from '@okta/okta-auth-js/types/lib/oidc/types';
import { AuthStatus, Tokens, IOktaLoginResponse } from '@features/okta/types';
import { InvitationAction } from '@features/invitation';
import { IS_EPHEMERAL } from '@app/globals/constants';
import { useTranslate } from 'shared/components/translation/translation.component';
import { push } from 'connected-react-router';
import { DataDogLogger } from '@features/datadog/DataDogLogger';
import { AuthAction, LogAndGetWidgetErrorAction, OktaContext, useAction } from '@features/datadog';
import { setLoggedIn } from 'features/okta/redux/oktaSlice';

const LazyOktaWidgetStyles = lazy(() =>
 import(/* webpackChunkName: "okta-widget-css-styles", webpackPrefetch: true */ './OktaWidgetStyles').then(({ OktaWidgetStyles }) => ({
  default: OktaWidgetStyles,
 }))
);

/**
 * Okta: User Login For
 *
 * @export
 * @return {*}
 */
export default function LoginWithOktaForm(): any {
 const { oktaAuth } = useOktaAuth();
 const widgetRef = useRef();
 const translate = useTranslate();
 const track = useAction();
 const dispatch = useDispatch();
 const resources = useSelector<NavCarrierState, Resources>(state => state.resources);


 // Fetch otp and state from query params from email callback verification URI
 // Application should have http://localhost:3000/login/callback
 // as the email callback verification URI
 const queryParams = new URLSearchParams(window.location.search);
 const state = queryParams.get('state');
 const config = getOktaConfig();

 const tokenVisible = queryParams.get('jwtvisible') == 'true';
 const isEphemeralOverride = queryParams.get('override') == 'ephemeral';
 const [token, setToken] = useState('');
 const [showToken, toggleToken] = useState(false);
 const [widgetError, setWidgetError] = useState(false);

 const copyToClipboard = async () => {
  await navigator.clipboard.writeText(token);
  alert('copied');
 };

 const handleInputChange = e => {
  setToken(e.target.value);
 };
  
 const handleWidgetResponse = async ({ tokens, status }: IOktaLoginResponse) => {
  if (status == 'SUCCESS') {
   const invitationToken = queryParams.get('invitationToken');

   // If the user comes from an email invitation, we only accept it after credentials are set successfully
   // and we stop the flow if the accept invitation request fails
   if (invitationToken) {
    try {
     await processInvitation({ token: invitationToken, action: InvitationAction.ACCEPT });
    } catch (error) {
     // TODO: Probably have a screen for errors in the future.
     console.error(error);
     
     return;
    }
   }

   oktaAuth.handleLoginRedirect(tokens);
   oktaAuth.tokenManager.setTokens(tokens);
   setToken(tokens.accessToken.accessToken);
   dispatch(setLoggedIn())
   // If the user (dev) has put `...&jwtvisible=true`,
   // // don't finish login, this was just to obtain tokens
   if (tokenVisible && !showToken) {
    toggleToken(true);
    return;
   }
  }
 };

 useEffect(() => {
  if (!widgetRef.current || !Object.keys(resources ?? {}).length) {
   return;
  }

  // Since Okta is using the "Reset your password" form for both new password creation and reset existing password,
  // we will conditionally change the form title base on the workflow indication get send via the url query param.
  for (const key in OktaWidgetStringOverrides) {
   if (queryParams.get('workflow') === 'activate') {
    OktaWidgetStringOverrides[key]['password.reset.title.generic'] = translate('CREATE_PASSWORD');
   }
  }

  const widget = new OktaSignIn({
   ...config,
   state,
   // Check for a recovery token from redirects to determine the appropriate flow for the widget to display.
   recoveryToken: queryParams.get('token') ?? '',
   i18n: OktaWidgetStringOverrides,
   helpLinks: {
    custom: [
     {
      text: translate('NO_ACCOUNT_SIGN_UP'),
      href: '/registration',
     },
    ],
   },
   features: {
    selfServiceUnlock: true,
   },
  });

  widget.renderEl(
   { el: widgetRef.current },
   async (res: any) => handleWidgetResponse(res as IOktaLoginResponse),
   err => {
    // TODO: Log this f@*&$ng error to DataDog properly!
    throw err;
   }
  );

  widget.on('afterError', (context, error) => {
   console.error(`Okta Widget Error: ${error?.message}`);
   console.error(error);
   const errorAction = LogAndGetWidgetErrorAction(context?.controller as OktaContext, error);
   track(errorAction, { error, context });

   if (context?.controller === 'recovery-loading' && error?.statusCode === 403) {
    setWidgetError(true);
   }
  });

  return () => widget.remove();
 }, [oktaAuth, resources]);

 const redirectClicked = async e => {
  const { tokens } = await oktaAuth.token.getWithPopup({ ...config, popupTitle: 'Navisphere Carrier Authenticator', pkce: true });
  oktaAuth.tokenManager.setTokens(tokens);
  dispatch(setLoggedIn())
 };

 return (
  <>
   <LazyOktaWidgetStyles />
   <div ref={widgetRef} />
   {(IS_EPHEMERAL || isEphemeralOverride) && (
    <div style={{ width: '100%', textAlign: 'center' }}>
     <button className="btn btn-secondary" style={{ margin: 5 }} onClick={redirectClicked}>
      Login with Redirect
     </button>
    </div>
   )}
   {widgetError && (
    <div style={{ width: '100%', textAlign: 'center' }}>
     <button className="btn btn-primary" style={{ margin: 5 }} onClick={() => (window.location.href = AppRoute.LOGIN)}>
      {translate('BACK_TO_LOGIN' as keyof IResources)}
     </button>
    </div>
   )}
   {showToken && (
    <div style={{ ...JwtBanner }}>
     <input type="text" value={token} title="Click to copy!" onChange={handleInputChange} style={{ width: '85%', margin: 5, borderRadius: 6 }} />
     <button type="button" onClick={copyToClipboard} className="btn btn-primary" style={{ margin: 5 }}>
      {' '}
      Click to Copy
     </button>
    </div>
   )}
  </>
 );
}
