import { useStoredCarrierDetail } from '@app/hooks/store/use-stored-user.hook';
import { DataDogProvider } from '@features/datadog';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { connectSignalR } from 'store/epics/app.actions';
import { FeatureFlagProvider } from '@containers/FeatureFlagProvider';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { updateCarrierForUser } from 'store/epics/carrier/carrier.actions';
import FullStory from 'react-fullstory';
import { BranchProvider } from '@features/branch-io';
import { useUserAndCarrierDataContext } from 'features/userAndCarrierData';
import { useDispatch } from 'react-redux';
import { toggleMembershipDialog } from 'features/permissions';
import { logOut } from '@features/okta/redux/oktaSlice';

interface Props extends JSX.IntrinsicAttributes {
 children: ReactNode | ReactNode[] | any;
}

const Consumer = ({ children }: Props) => {
 return <>{children}</>;
};

const WrapperConsumer = withLDConsumer()(Consumer);

const FSProps = {
 debug: false,
 org: '16VT8W',
 namespace: 'FS',
};

export const BootStrap = ({ children }: Props) => {
 const [wsConnected, setWsConnected] = useState(false);
 const updateCarrierInterval = 600000; //10 minutes

 const dispatch = useDispatch();
 const carrier = useStoredCarrierDetail();
 const { updateCarrierDetails, getUpdatedActiveMembership } = useUserAndCarrierDataContext();

 const { isAuthenticated } = useSelector<NavCarrierState>(s => s.auth) as AuthState;
 const organizationId = useSelector<NavCarrierState>(s => s.userManagement?.activeMembership?.organizationId) as string;
 const connectWebSockets = useDispatcher(connectSignalR);
 const updateCarrier = useDispatcher(updateCarrierForUser);

const updateMembershipsAndCarrierDetails = async () => {
  try {
    const activeMembership = await getUpdatedActiveMembership();
    if (activeMembership) {
      const { organizationId } = activeMembership;
      await updateCarrierDetails(organizationId);
    } else {
      dispatch(toggleMembershipDialog(true));
    }
  } catch {
    dispatch(logOut());
  }
}

 useEffect(() => {
  if (isAuthenticated && !wsConnected) {
   connectWebSockets();
   setWsConnected(true);
  }
 }, [isAuthenticated]);

 useEffect(() => {
  // do not update carrier details for vendors
  if (carrier?.carrierCode && !carrier?.carrierCode.startsWith('V')) {
   const interval = setInterval(async () => {
     organizationId ? updateMembershipsAndCarrierDetails() : updateCarrier();
   }, updateCarrierInterval);
   return () => clearInterval(interval);
  }
 }, [carrier]);

 return (
  <DataDogProvider>
   <BranchProvider />
   <FullStory {...FSProps} />
   <FeatureFlagProvider >
    <WrapperConsumer>{children}</WrapperConsumer>
   </FeatureFlagProvider>
  </DataDogProvider>
 );
};
