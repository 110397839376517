import React from 'react';
import { Observable } from 'rxjs';
import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { FinancialsRepository } from 'app/repositories/financials.repository';
import { useIsPaymentsUser } from '../hooks/use-is-payments-user.hook';
import { NotificationBadgeCounts } from '../types';
import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';

const defaultCounts = {
    waitingOnDocuments: 0,
};

export const NotificationBadgeContext = React.createContext<NotificationBadgeCounts>(defaultCounts);

export const NotificationBadgeProvider = ({ children }) => {
    const financialRepo = useRepository(FinancialsRepository);
    const isPaymentsUser = useIsPaymentsUser();
    const carrier = useStoredCarrierDetail();

    const [counts] = useAjaxResponse(() => {
        if (!isPaymentsUser) {
            return Observable.of<NotificationBadgeCounts>(defaultCounts);
        }

        return financialRepo
            .getPaymentCounts(carrier?.rollups.map(carrier => carrier.code))
            .catch(_ => Observable.of<NotificationBadgeCounts>(defaultCounts));
    }, [isPaymentsUser], { silent: true });

    return (
        <NotificationBadgeContext.Provider value={{ ...counts }}>
            {children}
        </NotificationBadgeContext.Provider>
    );
}
