import { lazy } from 'react';

export const LazyQuickEmailValidation = lazy(() =>
  import(/* webpackChunkName: "Invitation", webpackPrefetch: true */ '@features/quick-registration/components/QuickRegistrationContainer').then(
    ({ QuickRegistrationContainer }) => ({
      default: QuickRegistrationContainer,
    })
  )
);

export const LazyQuickRegistration = lazy(() =>
  import(/* webpackChunkName: "Invitation", webpackPrefetch: true */ '@features/quick-registration/components/QuickRegistrationDataForm').then(
    ({ QuickRegistrationDataForm }) => ({
      default: QuickRegistrationDataForm,
    })
  )
);