import { Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextInput } from '@chr/eds-react';
import { useTranslation } from 'react-i18next';
import { ManageUsersViews, AddUserViews } from 'features/account-settings';
import { validateEmailForOrganization } from 'api/userManagement';
import { LoadingButton } from '@features/okta/components/Buttons';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import * as Styles from 'features/account-settings/manage-users/styles';

export interface EmailEntryProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setManageUsersView: Dispatch<SetStateAction<symbol>>;
  setAddUserView: Dispatch<SetStateAction<AddUserViews>>;
}

export const EmailEntry = ({email, setEmail, setManageUsersView, setAddUserView}: EmailEntryProps) => {
  const [emailValidationError, setEmailValidationError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const organizationId = useSelector(s => s.userManagement?.activeMembership?.organizationId);

  const handleSubmission = () => {
    setEmailValidationError('');
    setIsLoading(true);
    validateEmailForOrganization(organizationId, email)
      .then(() => setAddUserView(AddUserViews.USER_INFORMATION))
      .catch((error) => handleEmailValidationFailure(error?.response?.data))
      .finally(() => setIsLoading(false))
  }

  const handleEmailValidationFailure = (errorResponse) => {
    if (errorResponse?.errors?.EmailAddress?.[0] === 'Invalid email address.') {
      setEmailValidationError(t('PLEASE_ENTER_A_VALID_EMAIL_ADDRESS_TO_CONTINUE'))
    } else if (errorResponse?.message === 3) {
      setEmailValidationError(t('THIS_EMAIL_ADDRESS_IS_ALREADY_REGISTERED'))
    }
  }

  return (
    <>
      <Box sx={Styles.EmailEntryContainer}>
        <Typography variant="heading300">
          {t('NEW_USERS_EMAIL_ADDRESS')}
        </Typography>
        <Box sx={Styles.EmailInput}>
          <TextInput
            label={t('EMAIL_ADDRESS')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            helperText={emailValidationError ? emailValidationError : t('EXAMPLE_EMAIL')}
            error={!!emailValidationError}
            fullWidth
          />
        </Box>

      </Box>
      <Box sx={Styles.EmailEntryButtonsContainer}>
        <Button
          variant="outlined"
          sx={Styles.CancelButton}
          onClick={() => setManageUsersView(ManageUsersViews.MANAGE_USERS)}
          data-testid="cancel-button"
        >
          {t('CANCEL')}
        </Button>
        <LoadingButton
          sx={Styles.EmailEntryButton}
          loading={isLoading}
          textResourceKey="CONTINUE"
          handleClick={handleSubmission}
        />
      </Box>
    </>
  );
}