export const Container = {
 padding: '0 16px',
};

export const Card = {
 margin: '27px auto',
 backgroundColor: '#FFFFFF',
 maxWidth: '670px',
 borderRadius: '8px',
 padding: {
  xs: '24px',
  sm: '48px',
 },
 display: 'flex',
 flexDirection: 'column',
 gap: '24px',
};

export const SupportContainer = {
 maxWidth: '670px',
 margin: '16px auto',
};

export const ImageWrapper = {
 display: 'flex',
 justifyContent: 'center',
};

export const Header = {
 color: '#222',
 fontFamily: 'Open Sans',
 fontSize: '24px',
 fontStyle: 'normal',
 fontWeight: '400',
 lineHeight: '32px',
};

export const Paragraph = {
 color: '#36383A',
 fontSize: '14px',
 fontStyle: 'normal',
 fontWeight: '400',
 lineHeight: '24px',
 letterSpacing: '0.5px',
};

export const Actions = {
 display: 'flex',
 justifyContent: 'center',
 gap: '8px',
};

export const Button = {
 minWidth: '144px',
};

export const Questions = {
 fontSize: '18px',
 fontStyle: 'normal',
 fontWeight: '600',
 lineHeight: '24px',
 letterSpacing: '0.15px',
 marginBottom: '16px',
};
