import { store } from 'store';
import { Http } from '@app/globals/constants';
import { offerResultNotificationReceived } from 'store/epics/notifications/notifications.actions';

const shipment = require("./mockFiles/shipment.json");
const shipmentDetail = require("./mockFiles/shipmentDetail.json");
const acceptedOffer = require("./mockFiles/offers/acceptedOfferSignalRResponse.json");
const counteredOffer = require("./mockFiles/offers/counteredReviewOfferSignalRResponse.json");
const furtherReviewOffer = require("./mockFiles/offers/furtherReviewOfferSignalRResponse.json");
const rejectedOffer = require("./mockFiles/offers/rejectedOfferSignalRResponse.json");

const getJsonResponse = (statusCode: number, body: object): Response => {
  return new Response(JSON.stringify(body), {
    status: statusCode,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

const getSlugFromUrl = (url: URL) => {
  try {
    const pathParts = url.pathname.split('/');
    const slug = pathParts.pop();
    // handle trailing slash
    return slug || pathParts.pop();
  } catch (error) {
    // Handle invalid URL
    return null;
  }
}

const getFutureDate = (minutes: number) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + minutes);
  const string = date.toISOString();
  return string;
}

export const mockedResponses: mockedResponse[] = [
  {
    method: Http.GET,
    path: '/search/v1/shipments',
    responseFunc: (url, request) => {
      const slug = getSlugFromUrl(url);
      const theShipmentIdIWantToMock = 12;
      if (parseInt(slug) === theShipmentIdIWantToMock) {
        return getJsonResponse(200, shipment);
      }
      return null;
    },
  },
  {
    method: Http.GET,
    path: '/shipments/v1/shipments',
    responseFunc: (url, request) => {
      const slug = getSlugFromUrl(url);
      const theShipmentIdIWantToMock = 12;
      if (parseInt(slug) === theShipmentIdIWantToMock) {
        return getJsonResponse(200, shipmentDetail);
      }
      return null;
    },
  },
  {
    method: Http.POST,
    path: '/offers/v1/offers/offer',
    responseFunc: () => getJsonResponse(200, { offerRequestId: "b44829d1-20fb-4d2b-bb7a-36361d784cfe" }),
    delayedResponse: {
      secondsOfDelay: 3, func: () => {
        const offerResultNotification: OfferResultNotification = {
          ...{ ...acceptedOffer, ...{ expirationDateTimeUtc: getFutureDate(15) } },
          receivedDate: new Date(),
        };
        store.dispatch(offerResultNotificationReceived(offerResultNotification));
      }
    }
  }
];