import { useEffect, useState } from 'react';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { AppRoute } from 'app/routesEnum';

export const Comm100Component = () => {
  const globallyPermitedRoutes = [
    AppRoute.FORGOT_USERNAME,
    AppRoute.FORGOT_USERNAME_SUCCESS,
    AppRoute.FORGOT_PASSWORD,
    AppRoute.FORGOT_PASSWORD_SUCCESS,
    AppRoute.RESET_PASSWORD,
    AppRoute.REGISTRATION,
    AppRoute.LOGIN_V2,
    AppRoute.LOGIN] as string[];

  const [show, setShow] = useState(false);

  const showComm100 = useSelector(state => state.auth?.carrier?.isNorthAmerican());
  const appInitialized = useSelector(state => state.app.initialized);
  const currentRoute = window.location.pathname;

  useEffect(() => {
    setShow((showComm100 || globallyPermitedRoutes.includes(currentRoute)) && appInitialized);
  }, [showComm100, appInitialized, currentRoute]);

  if (show) {
    return <div id="comm100-button-c632a808-1d37-46cc-9194-7b0f702d331e" />;
  }

  return null;
};
