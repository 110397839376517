import React from 'react';
import { useCallback, useMemo } from 'react';

import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { HazmatValidation } from 'shared/components/hazmat/hazmat-validation.component';
import { confirmHazmatStatus } from 'features/security/auth.actions';
import { useModal } from 'app/hooks/use-modal.hook';
import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';

import './hazmat-validation-modal.component.scss';

export const HazmatValidationModal: React.FC = () => {
  const currentCarrier = useStoredCarrierDetail();
  const pendingCarrier = useSelector(state => state.auth.pending.carrier);
  const carrier = pendingCarrier || currentCarrier;

  const confirmHazmat = useDispatcher(confirmHazmatStatus);

  const [, hide] = useModal('hazmat-expired');

  const headerKey = useMemo(() =>
    carrier
      ? (carrier.hasExpiredHazmat() ? 'HAZMAT_CERTIFICATE_EXPIRED' : 'HAZMAT_CERTIFICATE_EXPIRING_SOON')
      : null,
    [carrier]
  );

  const confirm = useCallback(() => pendingCarrier ? confirmHazmat() : hide(), [pendingCarrier]);

  return (
    <Modal
      disableClose
      name="hazmat-expired"
      header={headerKey}
      footer={<Button btnPrimary resource="OK" onClick={confirm} />}
    >
      <HazmatValidation suppressHeader />
    </Modal>
  );
};
