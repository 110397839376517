import { useState, useEffect } from 'react';
import { validateInvitationToken } from 'api/userManagement';
import { useBodyClass } from 'features/registration';
import { AcceptOrRejectInvitation, InvitationAccepted, InvitationRejected } from 'features/invitation';
import { Loader } from 'shared/components/loading/loading.component';
import { PageLevelError } from '@shared/components/error-boundary/error-boundary.component';
import { useOktaAuth } from '@okta/okta-react';
import { AuthRepository } from 'app/repositories/auth.repository';
import 'features/invitation/styles/invitation-landing.scss';

export const InvitationViews = Object.freeze({
 LANDING: Symbol('landing'),
 INVITATION_ACCEPTED: Symbol('invitation-accepted'),
 INVITATION_REJECTED: Symbol('invitation-rejected'),
});

export const InvitationLanding = () => {
 useBodyClass('body-background-color');
 const { oktaAuth } = useOktaAuth();
 const authRepo = new AuthRepository();
 const [data, setData] = useState<ValidateInvitationTokenResponse | undefined>();
 const [error, setError] = useState();
 const [loading, setLoading] = useState(false);
 const [view, setView] = useState(InvitationViews.LANDING);

 const isLandingView = view === InvitationViews.LANDING;
 const isInvitationAcceptedView = view === InvitationViews.INVITATION_ACCEPTED;
 const isInvitationRejectedView = view === InvitationViews.INVITATION_REJECTED;
 const userId = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims?.userId;
 const userDataRetrieved = !!userId && !!data;
 const query = new URLSearchParams(window?.location.search);

 useEffect(() => {
  const validateToken = async () => {
   setLoading(true);
   try {
    const response = await validateInvitationToken(query.get('token'));
    setData(response);
   } catch (error) {
    setError(error);
   } finally {
    setLoading(false);
   }
  };

  validateToken();
 }, []);

 // if logged in user doesn't match invitation user then clear out tokens and reload page
 useEffect(() => {
   if (userDataRetrieved && userId != data?.userId) {
     oktaAuth.tokenManager.clear();
     window.location.reload();
   }
 }, [data])

 if (loading) {
  return <Loader name="invitation" />;
 }

 if (error) {
  return <PageLevelError />;
 }

 if (!data) {
  return null;
 }

 return (
  <>
   {isLandingView && <AcceptOrRejectInvitation data={data} setView={setView} />}
   {isInvitationAcceptedView && <InvitationAccepted data={data} />}
   {isInvitationRejectedView && <InvitationRejected data={data} />}
  </>
 );
};
