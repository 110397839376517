import { useTranslation } from 'react-i18next';
import { useCustomerSupportNumber } from 'app/hooks/store/use-customer-support.hook';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import * as Styles from 'features/invitation/styles';

export const SupportSection = () => {
 const { t } = useTranslation();

 const customerSupportNumber = useCustomerSupportNumber();

 return (
  <Box sx={Styles.SupportContainer}>
   <Typography variant="body100">{t('QUESTIONS')}</Typography>
   <Box>
    <Link
     href="https://www.chrobinson.com/en-us/carriers/carrier-support/"
     target="_blank"
     rel="noreferrer"
     color="#0071c7"
     variant="body100"
     sx={{
      '&:hover': {
       textDecoration: 'none',
      },
     }}
    >
     {t('HELP_AND_FAQ')}
    </Link>
   </Box>
   <Typography variant="body100" sx={Styles.Paragraph}>
    {t('CONTACT_CARRIER_SERVICES_AT', { phoneNumber: customerSupportNumber })}
   </Typography>
  </Box>
 );
};
