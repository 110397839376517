import { Route } from 'react-router';
import { AppRoute } from '@app/routesEnum';
import { SiteSection } from '@shared/components/analytics/site-section.component';
import { LazyQuickEmailValidation, LazyQuickRegistration } from './index';

export default function QuickRegistrationRoutes() {
 return (
  <SiteSection name='Quick Registration'>
   <Route path={AppRoute.QUICK_EMAIL_VALIDATION} exact component={LazyQuickEmailValidation} />
   <Route path={AppRoute.QUICK_REGISTRATION} exact component={LazyQuickRegistration} />
  </SiteSection>
 );
}
