import { AppRoute } from 'app/routesEnum';
import { useRouterLocation } from 'app/hooks/store/use-router-location.hook';
import { useStoredUser, useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { useCookie } from 'app/hooks/use-cookie.hook';
import { breakpoints, useWindowSizeBreakpoints } from 'app/hooks/use-window-size-breakpoints.hook';
import { Grants } from 'features/security/grants';
import { Button } from 'shared/components/elements/button.component';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isAuthorized, UserPermissions } from 'features/permissions';

import './cash-advance-teaching.component.scss';

const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime().toString();

export const CashAdvanceTeaching = () => {
  const user = useStoredUser();
  const carrier = useStoredCarrierDetail();
  const hasPermission = isAuthorized([UserPermissions.AR]);
  const [open, toggle] = useState(false);
  const location = useRouterLocation();
  const dispatch = useDispatch();
  const isFullScreen = useWindowSizeBreakpoints(null, breakpoints.sm);
  const { enableTChekCallOutWeb } = useFlags();
  const [caTeaching, setCaTeaching] = useCookie(`CA_TEACHING-${user?.username}`);
  const { t } = useTranslation();

  const show = useCallback(() => {
    toggle(true);
  }, [toggle]);

  const hide = useCallback(() => {
    toggle(false);
  }, [toggle]);

  useEffect(() => {
    if (caTeaching === 'notShowAnyMore' || caTeaching > sevenDaysAgo || location.pathname.startsWith(AppRoute.MY_LOADS) || !enableTChekCallOutWeb || carrier.isEuropean()) {
      hide();
    } else {
      show();
    }
  }, [location, location.pathname, enableTChekCallOutWeb, user, caTeaching]);

  const setCookies = useCallback(() => {
    const currentTime = new Date().getTime().toString();
    setCaTeaching(caTeaching ? 'notShowAnyMore' : currentTime);
  }, [caTeaching]);

  const handleClose = useCallback(() => {
    hide();
    setCookies();
  }, [hide, setCookies]);

  const handleClick = useCallback(() => {
    dispatch(push(AppRoute.MY_LOADS));
    setCookies();
  }, [dispatch, push, setCookies]);

  if (!Grants.AccountsReceivableGrant(user) && !hasPermission) {
    return null;
  }

  return (
    <div className={classNames('teaching-callout-container', { hidden: !open })}>
      <div className="cube" />
      <div className="main-container">
        <div className="top">
          <div className="header">
            <Trans
              i18nKey="CASH_ADVANCE_TEACHING_CALLOUT_HEADER"
              components={[
                <b>{t('PAID_NOW')}</b>
              ]}
            />
          </div>
          <Button id="btn-teaching-callout-close" aria-label="Close" onClick={handleClose}>
            <svg width="10" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 1.41L12.50 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#FFFFFF" />
            </svg>
          </Button>
        </div>
        <div className="bottom">
          <Button btnDefault id="btn-teaching-callout-link" aria-label="Go to My Loads" onClick={handleClick}>
            <div className="link-button">
              {t(isFullScreen ? 'CASH_ADVANCE_TEACHING_CALLOUT_BUTTON' : 'CASH_ADVANCE_TEACHING_CALLOUT_BUTTON_MOBILE')}
              <span className="arrow icon-arrow_back" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
