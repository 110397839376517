import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import DesktopAccessDisabled from '@mui/icons-material/DesktopAccessDisabled';
import AccessTime from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import Typography from '@mui/material/Typography';
import { PaginationV2 } from '@shared/components/pagination-v2/pagination-v2';
import { SkeletonTableRows, MembershipStatus, FilterForm, roleTitleKeys } from 'features/account-settings';
import EmptyResultBox from 'assets/images/Empty_results_box.svg';
import * as Styles from 'features/account-settings/manage-users/styles';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useDispatch } from 'react-redux';
import { searchMemberships as searchMembershipsRequest } from 'api/userManagement';
import { setPageNumber, setItemsPerPage, setResponse, setLoading, setError } from 'features/account-settings/manage-users/redux/searchStateSlice';
import { useSnackbar } from '@chr/eds-react';

export const DataTable = ({ onEditClick }: { onEditClick: (userId: string) => void }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const dispatch = useDispatch();

  const { searchCriteria, pageNumber, itemsPerPage, response, isLoading, hasError } = useSelector(state => state.searchState);

  const memberships = response?.memberships ?? [];

  const activeMembership = useSelector(state => state.userManagement?.activeMembership);
  const organizationId = activeMembership?.organizationId;

  const statusMapper = useMemo(
    () => ({
      [MembershipStatus.ACTIVE]: {
        displayText: 'Active',
        color: 'rgba(0, 92, 5, 1)',
        icon: <CheckCircleOutline sx={Styles.StatusIcon} />,
      },
      [MembershipStatus.DISABLED]: {
        displayText: 'Suspended',
        color: 'rgba(181, 77, 8, 1)',
        icon: <DesktopAccessDisabled sx={Styles.StatusIcon} />,
      },
      [MembershipStatus.PENDING]: {
        displayText: 'Invitation Sent',
        color: 'rgba(0, 113, 199, 1)',
        icon: <AccessTime sx={Styles.StatusIcon} />,
      },
    }),
    []
  );
  const searchMemberships = useCallback(() => {
    dispatch(setError(false));
    dispatch(setLoading(true));
  
    const request: SearchMembershipRequest = {
      pageSize: itemsPerPage,
      pageNumber,
      searchCriteria: { organizationId, ...(searchCriteria ?? {}) },
    };
  
    return searchMembershipsRequest(request)
      .then(data => {
        dispatch(setResponse(data));
      })
      .catch(error => {
        dispatch(setError(true));
        const errors = error?.response?.data?.errors?.['SearchRequest.SearchCriteria.SearchText'] ?? [];
        const invalidCharacterError = errors.find((message: string) => message.includes('SearchRequest.SearchCriteria.SearchText cannot contain'));
  
        snackbar.enqueueSnackbar({
          id: 'search-memberships-failure',
          message: t(invalidCharacterError ? 'ERROR_INVALID_FIELD' : 'FALLBACK_SOMETHING_WENT_WRONG'),
          severity: 'error',
        });
      })
      .finally(() => {
        dispatch(setLoading(false)); // Use dispatch for loading state
      });
  }, [dispatch, itemsPerPage, pageNumber, organizationId, searchCriteria]);
  
  useEffect(() => {
    searchMemberships();
  }, [pageNumber, itemsPerPage, searchCriteria, organizationId]);
  return (
    <>
      <FilterForm isLoading={isLoading} />
      <Paper sx={Styles.TablePaper}>
        <TableContainer>
          <Table sx={Styles.TableComponent}>
            <TableHead sx={Styles.TableHead}>
              <TableRow>
                <TableCell sx={Styles.TableHeading}>{t('ACTION')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('STATUS')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('NAME')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('EMAIL')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('ROLE')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('COMPANY')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <SkeletonTableRows />
              ) : hasError ? (
                <TableRow>
                <TableCell colSpan={7}>
                  <Box sx={Styles.NoDataContainer}>
                  <img src={EmptyResultBox} alt="No data" style={{ marginBottom: '20px' }} />
                  <Typography variant="subtitle100">{t('MY_USERS_ERROR_FETCHING')}</Typography>
                  <Button variant="outlined" startIcon={<ReplayIcon />} onClick={searchMemberships}>
                    {t('TRY_AGAIN')}
                  </Button>
                  </Box>
                </TableCell>
                </TableRow>
              ) : !memberships.length ? (
                <TableRow>
                <TableCell colSpan={7}>
                  <Box sx={Styles.NoDataContainer}>
                  <img src={EmptyResultBox} alt="No data" style={{ marginBottom: '20px' }} />
                  <Typography variant="subtitle100">{t('MY_USERS_NO_RESULT_TITLE')}</Typography>
                  <Typography variant="body100">{t('MY_USERS_NO_RESULT_BODY')}</Typography>
                  </Box>
                </TableCell>
                </TableRow>
              ) : (
                memberships.map(({ userId, attributes, roles, status }) => {
                  const { displayText, color, icon } = statusMapper[status] ?? {};

                return (
                  <TableRow key={userId} sx={Styles.TableRow}>
                  <TableCell sx={Styles.TableCell} component="th" scope="row">
                    <Button variant="outlined" startIcon={<EditIcon />} onClick={() => onEditClick(userId)}>
                    {t('EDIT')}
                    </Button>
                  </TableCell>
                  <TableCell sx={{ ...Styles.TableCell, color }}>
                    {icon} {displayText}
                  </TableCell>
                  <TableCell sx={Styles.TableCell}>{`${attributes.firstName} ${attributes.lastName}`}</TableCell>
                  <TableCell sx={Styles.TableCell}>{attributes.email}</TableCell>
                  <TableCell sx={Styles.TableCell}>{t(roleTitleKeys[roles[0]])}</TableCell>
                  <TableCell sx={Styles.TableCell}>{attributes.companyName}</TableCell>
                  </TableRow>
                );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: isLoading ? 'flex' : 'none', justifyContent: 'space-between', padding: '16px' }}>
          <Skeleton animation="wave" variant="rounded" width="200px" />
          <Skeleton animation="wave" variant="rounded" width="200px" />
        </Box>
        <Box sx={{ ...Styles.PaginationContainer, display: !isLoading && Boolean(response?.memberships?.length) ? 'block' : 'none' }}>
          <PaginationV2
            totalItems={response?.totalPossible ?? 0}
            pageNumber={pageNumber}
            itemsPerPage={itemsPerPage}
            setPageNumber={(value: number) => dispatch(setPageNumber(value))}
            setItemsPerPage={(value: number) => dispatch(setItemsPerPage(value))}
            closeDetails={() => {}}
            isBanner={false}
            tab="table-pagination"
          />
        </Box>
      </Paper>
    </>
  );
};
