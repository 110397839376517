export enum LegacyAppRoute {
  BIN_LOAD_2 = '/Global/GlobalDefault.aspx?FindLoadsBookItNowLoad=true', // &caller=email&loadnumber=101900539&costquoteid=1279416&costquotesequencenumber=0&currencycode=cad&totalcost=3000
  BIN_BATCH_2 = '/Global/GlobalDefault.aspx?FindLoadsBookItNowBatch=true', // &caller=email&loadnumber=101900539&costquoteid=1279416&costquotesequencenumber=0&currencycode=cad&totalcost=3000
  LOGIN = '/Global/GlobalDefault.aspx',
  FORGOT_USERNAME = '/global/GlobalLoginHelp.aspx',
  FORGOT_USERNAME_SUCCESS = '/global/GlobalLoginHelp.aspx',
  FORGOT_PASSWORD = '/global/GlobalLoginHelp.aspx',
  FORGOT_PASSWORD_SUCCESS = '/global/GlobalLoginHelp.aspx',
  RESET_PASSWORD = '/Applications/ChangePassword.aspx',
  REGISTRATION = '/Global/GlobalRegistration.aspx',
  REGISTRATION_SUCCESS = '/Global/GlobalRegistrationComplete.aspx',
  BIN_LOAD = '/Applications/FindLoad/RadiusSearchOD.aspx?Feature=FindLoadsBookItNowLoad',
  BIN_BATCH = '/Applications/FindLoad/RadiusSearchOD.aspx?Feature=FindLoadsBookItNowBatch',
  FIND_LOADS_SINGLE = '/Applications/FindLoad/RadiusSearchOD.aspx',
  FIND_LOADS_MULTI = '/Applications/FindLoad/FindLoadMultiple.aspx',
  FIND_LOADS_MAP = '/Applications/FindLoad/DynamicMapSearch.aspx',
  FIND_LOAD_DETAILS = '/Applications/FindLoad/FindLoadDetails.aspx', // ?LoadNumber=:id
  POST_TRUCKS = '/Applications/PostTruck/PostTruck.aspx',
  MY_LOADS = '/Applications/LoadBoard/CarrierBoard.aspx',
  CHECK_CALL = '/Applications/LoadBoard/CheckCall.aspx',
  MY_LOAD_DETAILS = '/Applications/LoadBoard/CarrierBoardDetail.aspx', // ?LoadNumber=:id&Carrier=:carrier&BookType=:bookType
  TENDER_DETAILS = '/Applications/TMC/TenderDetail.aspx', // ?LoadNumber=101156415&CarrierID=T493386&TenderSequenceNumber=14484
  TENDERS = '/Applications/TMC/Tenders.aspx',
  TENDERS_EMAIL = '/Applications/EmailLanding/EmailLogIn.aspx', // ?action=accept&loadNumber=313543143
  SPOT_BIDS = '/Applications/TMC/SortSpotBids.aspx',
  ACCOUNTS_RECEIVABLE = '/Applications/AR/AR.aspx',
  RATES = '/Applications/Rates/Rates.aspx',
  PREFERRED_LANES = '/Applications/Profile/Profile.aspx',
  ADMINISTER_USERS = '/Applications/WebAdmin/UserList.aspx',
  IMPERSONATE_USER = '/Applications/Impersonate.aspx',
  ANNOUNCEMENTS = '/Applications/Announcements/Announcements.aspx',
  DOCK_MANAGER = '/Applications/CDMS/Login/welcome.aspx',
  TMC_REPORTS = '/Applications/TMC/WebReport.aspx',
  OFFERS = '/Applications/FindLoad/OfferBoard.aspx',
  CONTACT_US = '/Applications/Contact_Us.aspx',
  EMAIL_PREFERENCES = '/Applications/Profile/Profile.aspx?IgnoreMobile=true',
  MY_SETTINGS = '/Applications/Profile/MySettings.aspx?IgnoreMobile=true',
  SMS_SUBSCRIPTION = '/Global/Subscriptions/UpdateSubscription.aspx', // this goes to full screen mobile app on legacy
  GLOBAL_LOGIN_HELP = '/Global/GlobalLoginHelp.aspx',
  REGISTRATION_SUPPORT = '/Global/GlobalRegistration.aspx',
  REGISTRATION_EMAIL = '/Global/GlobalRegistration.aspx',
  SETTINGS = '/Applications/Profile/MySettings.aspx',
  CLAIMS = '/Applications/AR/CarrierClaims.aspx',
  MANAGE_INVOICES = '/Applications/ManageInvoices/ManageInvoices.aspx',
  EDIT_INVOICE = '/Applications/ManageInvoices/CreateInvoice.aspx?action=edit&id=', // ?action=edit&id={0}
  CREATE_INVOICE = '/Applications/ManageInvoices/CreateInvoice.aspx',
  VIEW_INVOICE = '/Applications/ManageInvoices/ViewInvoice.aspx', // ?val=12345
}

export enum BetaAppRoute {
  // ROOT = '/',
  // HOME = '/home',
  LOGIN = '/login',
  FORGOT_USERNAME = '/login/forgot-username',
  FORGOT_USERNAME_SUCCESS = '/login/forgot-username/success',
  FORGOT_PASSWORD = '/login/forgot-password',
  FORGOT_PASSWORD_SUCCESS = '/login/forgot-password/success',
  RESET_PASSWORD = '/login/reset-password',
  REGISTRATION = '/registration',
  REGISTRATION_SUCCESS = '/registration/success',
  BIN_LOAD = '/book-it-now/', // book-it-now/{0} - Batch Link: /book-it-now
  BIN_LOAD_2 = '/book-it-now/', // book-it-now/{0} - Batch Link: /book-it-now
  BIN_BATCH = '/book-it-now',
  FIND_LOADS_MULTI = '/find-loads/multi',
  FIND_LOADS_MAP = '/find-loads/map-search',
  FIND_LOADS_SINGLE = '/find-loads/single',
  FIND_LOAD_DETAILS = '/find-load-details/', // ** MAPPER** loadNumber
  POST_TRUCKS = '/post-trucks',
  MY_LOADS = '/my-loads',
  CHECK_CALL = '/my-loads',
  MY_LOAD_DETAILS = '/load-details',  // ID + ?bookType=TL carrierCode
  TENDER_DETAILS = '/tenders/', // /${LoadNumber}/${TenderSequenceNumber}
  TENDERS = '/tenders',
  TENDERS_EMAIL = '/tenders/',
  SPOT_BIDS = '/spot-bids',
  ACCOUNTS_RECEIVABLE = '/accounts-receivable',
  RATES = '/rates',
  PREFERRED_LANES = '/preferred-lanes',
  ADMINISTER_USERS = '/administer-users',
  IMPERSONATE_USER = '/impersonate-user',
  ANNOUNCEMENTS = '/announcements',
  DOCK_MANAGER = '/dock-manager',
  TMC_REPORTS = '/tmc-reports',
  OFFERS = '/my-offers',
  CONTACT_US = '/contact-us',
  EMAIL_PREFERENCES = '/settings',
  MY_SETTINGS = '/settings',
  GLOBAL_LOGIN_HELP = '/login',
  REGISTRATION_SUPPORT = '/registration',
  REGISTRATION_EMAIL = '/registration',
  SMS_SUBSCRIPTION = '/settings/sms', // This goes to full screen mobile app on legacy
  SETTINGS = '/settings',
  CLAIMS = '/claims',
  MANAGE_INVOICES = '/manage-invoices',   // /manage-invoices/view-invoice/{0}
  EDIT_INVOICE = '/manage-invoices/edit-invoice/',  // ** MAPPER** /manage-invoices/edit-invoice/{invoiceNumber}
  CREATE_INVOICE = '/manage-invoices/create-invoice',
  VIEW_INVOICE = '/manage-invoices/view-invoice/',  // ID  ** MAPPER** /manage-invoices/view-invoice/{invoiceNumber}
}
