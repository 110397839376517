import { Fragment, ReactNode, useEffect } from 'react';
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import Container from 'typedi';
import { setLdUserIdentity } from 'features/analytics/redux/analytics-v2.actions';
import { useDispatch } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { LDEvaluationDetail, LDInspectionFlagUsedHandler, LDUser } from 'launchdarkly-js-client-sdk';
import { CarrierDivision } from '@shared/models/carrier/carrier-division.model';
import { useUserAndCarrierDataContext } from 'features/userAndCarrierData';
import { useSelector } from 'app/hooks/store/use-selector.hook';

interface Props extends JSX.IntrinsicAttributes{
  children?: ReactNode;
  userId?: string;
  carrierCode?: string;
}

const sendEvents = process.env.NODE_ENV != 'test';

const clientConfig = {
  clientSideID: Container.get<string>('appConstants.launchDarklyClientId'),
  options: {
    sendEvents: sendEvents, // send analytics events to launch darkly
    diagnosticOptOut: true, // opt out of diagnostics data to LaunchDarkly
    flushInterval: 2000, // interval inbetween flushes of the analytics events queue in ms
    eventCapacity: 500, // capacity of the analytics event queue
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (flagKey: string, flagDetail: LDEvaluationDetail, user: LDUser) => {
          datadogRum.addFeatureFlagEvaluation(flagKey, flagDetail.value);
        },
      } as LDInspectionFlagUsedHandler,
    ]
  }
};

const FeatureFlagComponent = ({ children }: Props) => {
  const ldClient = useLDClient();
  const dispatch = useDispatch();

  const isOktaAuthed = useSelector(s => s.okta.isAuthenticated);
  const carrier = useSelector(s => s.auth?.carrier);
  const oktaClaims = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims;
  const userId = oktaClaims?.carrierId || oktaClaims?.userId;
  const { finishLogin } = useUserAndCarrierDataContext();
  Container.set('LD_CLIENT', ldClient);

  // get user specific flags and login
  useEffect(() => {

    if (userId && ldClient) {
      ldClient
        .identify({ key: `${userId}` })
        .then((response) => {
          finishLogin(response.enableUserManagement, response.findLoadsSendAnalyticsWeb); // login user once we've gotten their user specific flags
        });
      ldClient.flush();
    }
  }, [userId, ldClient, isOktaAuthed])

  // get carrier specific flags retrigger on carrier switch
  useEffect(() => {
    if (userId && ldClient && carrier) {
      ldClient
        .identify({
          key: `${userId}`,
          custom: {
            carrierCode: carrier?.carrierCode,
            rollupDivisions: CarrierDivision.retrieveRollupDivisions(carrier),
            carrierSize: carrier?.carrierSize,
          }
        })
        .then(() => {
          dispatch(setLdUserIdentity(true));
        });
      ldClient.flush();
    }
  }, [carrier])

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

export const FeatureFlagProvider = withLDProvider<Props>(clientConfig)(FeatureFlagComponent);
