import moment, { Moment } from 'moment';
import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import * as a from './find-loads.actions';

import { AppRoute } from 'app/routesEnum';
import { scriptLoaderObservable } from 'app/util/util';
import { API_DATE_FORMAT } from 'app/globals/constants';
import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { InterimRepository } from 'app/repositories/interim.repository';
import { searchHistoryEpic } from 'pages/find-loads/search-history/search-history.epics';
import { SELECT_SEARCH_HISTORY, SelectSearchHistoryAction } from 'pages/find-loads/search-history/search-history.actions';
import { parseAvailableLoadsQueryString } from 'providers/available-load-query-string.provider';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { getMicrosoft } from 'providers/microsoft.provider';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { FindLoadsState } from 'shared/find-loads/redux/find-loads.reducers';
import { ProcessAvailableLoadsSearchAction, processAvailableLoadsSearchParams, PushURLAction } from 'store/epics/find-loads/find-loads-base.actions';
import { deferActionUntilPostLogin } from 'features/security/auth.actions';
import { UserRepository } from 'app/repositories/user.repository';
import { ToastManager } from 'shared/components/toast/toast.actions';
import { UserPreferredLane } from 'shared/models/preferred-lane.model';
import { Translation } from 'shared/components/translation/translation.component';
import { takeUntil } from 'rxjs/operators';
import { AvailableLoadSummaryResponse } from 'shared/models/loads/available-load-summary-response.model';
import { getRecommendedShipmentsAsync, searchAvailableShipmentsAsync, searchReloadShipmentsAsync, getAvailableShipmentSummaryAsync } from 'api/search';
import { callCarrierValidation } from 'api/gateway';

interface EpicDependencies {
  availableLoadsRepo?: AvailableLoadsRepository;
  locationRepo?: InterimRepository;
  userRepo?: UserRepository;
  toasts: ToastManager;
  bingMapsKey: string;
}

type FindLoadsEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, EpicDependencies, { findLoads: FindLoadsState }>;

export const loadBingMapsScriptEpic: FindLoadsEpic = (action$, state$, { bingMapsKey }) =>
  action$.ofType(a.FETCH_MICROSOFT_SCRIPT)
    .filter(() => !state$.value.findLoads.isMicrosoftLoaded)
    .mergeMap(() =>
      scriptLoaderObservable(`www.bing.com/api/maps/mapcontrol?key=${bingMapsKey}`)
        .map(() => a.microsoftLoadSuccess(Boolean(getMicrosoft())))
    );

async function getShipmentByIdAsyncAdapter(shipmentId: number): Promise<AvailableLoadSummaryResponse> {
  const shipmentResult = await getAvailableShipmentSummaryAsync(shipmentId);
  if (!shipmentResult.success) {
    throw new APIErrorResponse(shipmentResult.error.message as unknown as API.ErrorResponse, shipmentResult.error.code);
  }

  const response = new AvailableLoadSummaryResponse({ results: null }, false);

  if (shipmentResult.value) {
    response.results = [shipmentResult.value];
  }

  return response;
}

async function getRecommendedShipmentsAsyncAdapter(correlationId: string): Promise<AvailableLoadSummaryResponse> {
  const shipmentsResult = await getRecommendedShipmentsAsync(correlationId);
  if (!shipmentsResult.success) {
    throw new APIErrorResponse(shipmentsResult.error.message as unknown as API.ErrorResponse, shipmentsResult.error.code);
  }
  return shipmentsResult.value;
}

async function searchAvailableShipmentsAsyncAdapter(correlationId: string, criteria: AvailableLoadSearchCriteriaJSON): Promise<AvailableLoadSummaryResponse> {
  const shipmentsResult = await searchAvailableShipmentsAsync(correlationId, criteria);
  if (!shipmentsResult.success) {
    throw new APIErrorResponse(shipmentsResult.error.message as unknown as API.ErrorResponse, shipmentsResult.error.code);
  }
  return shipmentsResult.value;
}

async function searchReloadShipmentsAsyncAdapter(reloadsCriteria: ReloadShipmentsSearchCriteriaJSON): Promise<AvailableLoadSummaryResponse> {
  const reloadShipmentsResult = await searchReloadShipmentsAsync(reloadsCriteria);
  if (!reloadShipmentsResult.success || !reloadShipmentsResult.value?.loads) {
    throw new APIErrorResponse(reloadShipmentsResult.error.message as unknown as API.ErrorResponse, reloadShipmentsResult.error.code);
  }

  return AvailableLoadSummaryResponse.fromReloadsResponse(reloadShipmentsResult.value);
}

export const searchLoadsEpic: FindLoadsEpic = (action$, state$, { availableLoadsRepo }) =>
  action$.ofType<a.SearchAvailableLoadsAction>(a.SEARCH_AVAILABLE_LOADS)
    .map(({ criteria }): typeof criteria =>
      (state$.value.auth.carrier?.hasValidHazmatCertification())
        ? criteria
        // if user has no hazmat certification, include `hazmatLoad: false` in their search, otherwise that value is null (hazmat = any)
        : { ...criteria, hazmatLoad: false }
    )
    .mergeMap((criteria) => {
      const isNorthAmerica = state$.value.auth.carrier?.isNorthAmerican();
      const captchaToken = state$.value.captcha.findLoads.token;
      const findLoadsV2 = isNorthAmerica;

      // do not perform search if captchaToken is null, this is primarily to stop performing search on reload with criteria present.
      if (findLoadsV2 && !captchaToken) {
        return Observable.of();
      }

      let searchCall;
      const correlationId = state$.value.analytics?.search?.searchCorrelationId;

      if (criteria?.reloadsCriteria) {
        searchCall = Observable.from(searchReloadShipmentsAsyncAdapter(criteria.reloadsCriteria));
      } else if (criteria?.loadNumber) {
        // LOAD NUM SEARCH
        searchCall = Observable.from(getShipmentByIdAsyncAdapter(criteria.loadNumber))
          .mergeMap((loadNumSearchResult) => {
            const carrierBooks = state$.value.findLoads.results?.carrierBooks;
            const notBookedLoads = !carrierBooks ? loadNumSearchResult?.results : loadNumSearchResult?.results.filter(c => !carrierBooks.some(d => d.loadNumber === c.number));
            const loadNumSearchEmpty = loadNumSearchResult?.results && notBookedLoads.length === 0;
            if (isNorthAmerica && loadNumSearchEmpty) {
              return Observable.from(getRecommendedShipmentsAsyncAdapter(correlationId));
            } else {
              return Observable.of(loadNumSearchResult);
            }
          });
      } else {
        // ORIGIN/DESTINATION SEARCH
        searchCall = (isNorthAmerica
          ? Observable.from(searchAvailableShipmentsAsyncAdapter(state$.value.analytics?.search?.searchCorrelationId, criteria))
          : availableLoadsRepo.search(criteria));
      }

      return searchCall
        .map(a.searchAvailableLoadsSucceeded)
        .catch((res: APIErrorResponse) => {
          return ActionsObservable.of(a.searchAvailableLoadsFailed(res));
        });
    });

const saveSearchedLocations: FindLoadsEpic = (action$, state$, { availableLoadsRepo }) =>
  action$.ofType<a.SearchAvailableLoadsAction>(a.SEARCH_AVAILABLE_LOADS)
    .map(action => availableLoadsRepo.saveSearchHistoryLocations(state$.value?.auth?.user?.userId, action.criteria))
    .ignoreElements();

// conditions to show suggested loads
// search by origin/dest before any search is performed
// search by load # before any search is performed
// search by load # after a search is performed with 0 results
// does not show if any results come back from searching the form
export const getSuggestedLoadsEpic: FindLoadsEpic = (action$, state$) =>
  action$.ofType(a.GET_SUGGESTED_LOADS)
    .mergeMap(() => {
      const searchCorrelationId = state$.value.analytics?.search?.searchCorrelationId;
      return (
        Observable.from(getRecommendedShipmentsAsyncAdapter(searchCorrelationId))
          .pipe(takeUntil(action$.ofType(a.SEARCH_AVAILABLE_LOADS, a.GET_SUGGESTED_LOADS)))
          .map(a.searchAvailableLoadsSucceeded)
          .catch((res: APIErrorResponse) => ActionsObservable.of(a.searchAvailableLoadsFailed(res)))
      );
    });

const getErrorMessage = (res) => {
  return res.errors && (res.errors.length > 0) && res.errors[0].code.shortCode === '1090122'
    ? <Translation resource="PREFERRED_LANE_ALREADY_EXISTS" />
    : <Translation resource="ERROR_CREATING_PREFERRED_LANE" />;
};

export const addPreferredLaneSuccessEpic: FindLoadsEpic = (action$, state$, { toasts }) =>
  action$.ofType(a.ADD_PREFERRED_LANE_SUCCESS)
    .map(() => toasts.success([<Translation resource="PREFERRED_LANE_CREATED" />]))
    .ignoreElements();

export const addPreferredLaneErrorEpic: FindLoadsEpic = (action$, state$, { toasts }) =>
  action$.ofType(a.ADD_PREFERRED_LANE_ERROR)
    .map(({ error }) => toasts.error([getErrorMessage(error)]))
    .ignoreElements();

export const savePreferredLaneEpic: FindLoadsEpic = (action$, state$, { userRepo }) =>
  action$.ofType(a.PUSH_AVAILABLE_LOAD_SEARCH_URL)
    .filter(({ saveAsPreferredLane }) => saveAsPreferredLane)
    .filter(({ criteria }) => Boolean(criteria))
    .mergeMap((action: PushURLAction) =>
      userRepo.getPreferredLanes(state$.value.auth.user)
        .map(lanes => lanes.filter(lane => lane.preferredLane.emailNotifications).length < 10)
        .map(laneNotification => ({ ...action, laneNotification }))
        .catch((err: AjaxError) => Observable.throw(new APIErrorResponse(err.response, err.status)))
    )
    .map(({ criteria, laneNotification }) => createLaneFromSearchCriteria(criteria, laneNotification, state$))
    .mergeMap(lane =>
      userRepo.addPreferredLane(lane)
        .map(() => a.addPreferredLaneSuccess(lane))
        .catch((res) => ActionsObservable.of(a.addPreferredLaneError(res)))
    );

export const refreshLoadsEpic: FindLoadsEpic = (action$, state$) =>
  action$.ofType(a.REFRESH_AVAILABLE_LOADS)
    .filter(() => state$.value.router.location.pathname.startsWith(AppRoute.FIND_LOADS_BASE))
    .map(() => !!state$.value.findLoads.searchCriteria ?
      a.searchAvailableLoads({ ...state$.value.findLoads.searchCriteria }) :
      a.getSuggestedLoads());

export const carrierValidationEpic: FindLoadsEpic = (action$, state$) =>
  action$.ofType(a.CALL_CARRIER_VALIDATION)
    .mergeMap(async ({ payload }) => {
      const validationResult = await callCarrierValidation(payload);
      if (validationResult.success) {
        return a.storeCarrierValidationResult(payload, validationResult.value);
      } else {
        return a.storeCarrierValidationResult(payload, null);
      }
    });

const formatDate = (date?: Moment) => date ? date.format(API_DATE_FORMAT) : null;

export const searchHistorySelectedEpic: FindLoadsEpic<a.CriteriaAction> = (action$, state$) =>
  action$.ofType<SelectSearchHistoryAction>(SELECT_SEARCH_HISTORY)
    .map((action): [typeof action, LDClient] => [action, Container.get<LDClient>('LD_CLIENT')])
    .map(([action, ldClient]): [typeof action, LDFlagSet] => [action, ldClient.allFlags()])
    .map(([{ entry }, ldFlags]) => entry.toSearchCriteria(ldFlags['find-loads-a-b-testing'], state$))
    .map(criteria => ({ ...criteria, pickupStart: formatDate(moment().hours(0).minute(0).seconds(0)) }))
    .map(criteria => ({ ...criteria, pickupEnd: formatDate(moment().hours(0).minute(0).seconds(0).add(1, 'day')) }))
    .map(criteria => a.pushAvailableLoadsSearchURL(criteria));

// Begin processing the query string if the user is logged in
const convertQueryParamsToSearchCriteria: FindLoadsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.PROCESS_AVAILABLE_LOADS_SEARCH_PARAMS)
    .filter(() => state$.value.auth.isAuthenticated)
    .filter(() => state$.value.router.location.pathname !== AppRoute.FIND_LOADS_BASE) // don't run before adding /single, etc
    .filter(() => state$.value.router.location.pathname.startsWith(AppRoute.FIND_LOADS_BASE)) // don't run on any other part of the site
    .map((action): [typeof action, LDClient] => [action, Container.get<LDClient>('LD_CLIENT')])
    .map(([action, ldClient]): [typeof action, LDFlagSet] => [action, ldClient.allFlags()])
    .map(([action, ldFlags]) => parseAvailableLoadsQueryString(action.search, ldFlags['find-loads-a-b-testing'], state$.value.auth.carrier?.isCarrierCodeOdd))
    .map(a.processIncomingCriteria);

// Given a location change, pipe to an action to process the query string (or an action that queues up processing the query string)
const retrieveSearchParamsFromQueryStringEpic: FindLoadsEpic<ProcessAvailableLoadsSearchAction> = action$ =>
  action$.ofType<LocationChangeAction>(LOCATION_CHANGE)
    .filter(({ payload }) => payload.location.pathname !== AppRoute.FIND_LOADS_BASE)
    .filter(({ payload }) => payload.location.pathname.startsWith(AppRoute.FIND_LOADS_BASE))
    .map(({ payload }) => processAvailableLoadsSearchParams(payload.location.search));

// If there is no criteria, clear the search and set the search type to single
const processIncomingNullCriteriaEpic: FindLoadsEpic = (action$, state$) =>
  action$.ofType(a.PROCESS_INCOMING_CRITERIA)
    .filter((action: a.CriteriaAction) => action.criteria == null)
    .map(() => state$.value.router.location.pathname.split('/').pop())
    .map((searchType: AvailableLoadSearchType) => a.clearSearch(searchType || AvailableLoadSearchType.SINGLE));

// Given search criteria, apply the search and set the search type
const processIncomingCriteriaEpic: FindLoadsEpic = (action$, state$) =>
  action$.ofType(a.PROCESS_INCOMING_CRITERIA)
    .filter(() => state$.value.router.location.pathname !== AppRoute.FIND_LOADS_BASE)
    .filter((action: a.CriteriaAction) => action.criteria != null)
    .map((action: a.CriteriaAction) => ({
      criteria: action.criteria,
      searchType: action.criteria?.reloadsCriteria
        ? AvailableLoadSearchType.RELOADS
        : action.criteria?.destinationStateCodes
          ? AvailableLoadSearchType.MULTI
          : AvailableLoadSearchType.SINGLE
    }))
    .map(({ criteria, searchType }) => a.applySearch(criteria, searchType));

// Given criteria, set the criteria to the store
const setSearchCriteriaEpic: FindLoadsEpic<a.CriteriaAction> = action$ =>
  action$.ofType(a.APPLY_FIND_LOADS_SEARCH)
    .map(action => action.criteria)
    .map(a.setSearchCriteria);

// Given criteria call the search API with the criteria
const performSearchEpic: FindLoadsEpic<a.CriteriaAction> = action$ =>
  action$.ofType(a.APPLY_FIND_LOADS_SEARCH)
    .map(action => action.criteria)
    .map(a.searchAvailableLoads);

// Given search type, set the search type to the store
const setSearchTypeEpic: FindLoadsEpic = (action$, state$) =>
  action$.ofType(a.APPLY_FIND_LOADS_SEARCH, a.CLEAR_FIND_LOADS_SEARCH)
    .map(action => action.searchType)
    .filter(type => state$.value.findLoads.searchType !== type)
    .map(a.setSearchType);

// Given search type, set the search type to the store
const resetSearchResultsEpic: FindLoadsEpic = (action$, state$) =>
  action$.ofType<LocationChangeAction>(LOCATION_CHANGE)
    .map(({ payload }) => payload.location.pathname)
    .filter(newPathName => newPathName !== AppRoute.FIND_LOADS_BASE)
    .filter(newPathName => newPathName.startsWith(AppRoute.FIND_LOADS_BASE))
    .map(newPathName => [newPathName, state$.value.router.location.pathname])
    .filter(([newPathName, oldPathName]) => newPathName !== oldPathName)
    .map(a.resetAvailableLoads);

// clear the search criteria from the store
const unsetCriteriaEpic: FindLoadsEpic = action$ =>
  action$.ofType(a.CLEAR_FIND_LOADS_SEARCH)
    .map(a.unsetSearchCriteria);

// Defer processing the query string if the user is NOT logged in
const deferProcessingQueryStringEpic: FindLoadsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(a.PROCESS_AVAILABLE_LOADS_SEARCH_PARAMS)
    .filter(() => !state$.value.auth.isAuthenticated)
    .map(deferActionUntilPostLogin);

// These epics are for reading the incoming search criteria from the URL
const processSearchURLEpic: FindLoadsEpic = (action$, state$, deps) => combineEpics(
  retrieveSearchParamsFromQueryStringEpic,
  convertQueryParamsToSearchCriteria,
  processIncomingNullCriteriaEpic,
  processIncomingCriteriaEpic,
  setSearchCriteriaEpic,
  performSearchEpic,
  setSearchTypeEpic,
  unsetCriteriaEpic,
  savePreferredLaneEpic,
  resetSearchResultsEpic,
  deferProcessingQueryStringEpic,
  addPreferredLaneSuccessEpic,
  addPreferredLaneErrorEpic
)(action$, state$, deps);

// All find loads epics
export const findLoadsEpic: FindLoadsEpic = (action$, state$) => combineEpics(
  loadBingMapsScriptEpic,
  searchLoadsEpic,
  saveSearchedLocations,
  refreshLoadsEpic,
  carrierValidationEpic,
  searchHistoryEpic,
  searchHistorySelectedEpic,
  processSearchURLEpic,
  getSuggestedLoadsEpic
)(action$, state$, {
  availableLoadsRepo: Container.get(AvailableLoadsRepository),
  locationRepo: Container.get(InterimRepository),
  bingMapsKey: Container.get('appConstants.bingMapsKey'),
  userRepo: Container.get(UserRepository),
  toasts: Container.get(ToastManager),
});

export const makeLocationObject = (locations) => {
  let stateCodes = [];

  locations.forEach(country => {
    const states = country.value.map(state => ({ countryCode: country.key, stateProvinceCode: state }));
    stateCodes = stateCodes.concat(states);
  });
  return stateCodes;
};

export const createLaneFromSearchCriteria = (criteria, laneNotification, state$) => {
  return new UserPreferredLane({
    userId: state$.value.auth.user.userId,
    userPreferredLaneId: criteria.userPreferredLaneId || null,
    preferredLane: {
      carrierCode: state$.value.auth.carrier.carrierCode || null,
      createdDateTime: moment().format(API_DATE_FORMAT),
      destination:
        !criteria.destinationStateCodesByCountryCode
          ?
          {
            city: criteria.destinationCity || null,
            stateProvinceCode: criteria.destinationStateProvinceCode || null,
            countryCode: criteria.destinationCountryCode || null,
          }
          : null,
      destinationRadiusMiles: criteria.destinationRadiusMiles || null,
      destinationStateCodes:
        criteria.destinationStateCodes && (criteria.destinationStateCodes.length > 0)
          ? makeLocationObject(criteria.destinationStateCodesByCountryCode)
          : null,
      equipmentLength: criteria.equipmentLengthMax || null,
      equipmentMode: criteria.mode || null,
      maximumTotalDistance: criteria.maximumTotalDistance || null,
      maximumTotalWeight: criteria.maximumTotalWeight || null,
      minimumTotalDistance: criteria.minimumTotalDistance || null,
      minimumTotalWeight: criteria.minimumTotalWeight || null,
      origin:
        !criteria.originStateCodesByCountryCode
          ?
          {
            city: criteria.originCity || null,
            stateProvinceCode: criteria.originStateProvinceCode || null,
            countryCode: criteria.originCountryCode || null,
          }
          : null,
      originRadiusMiles: criteria.originRadiusMiles || null,
      originStateCodes:
        criteria.originStateCodes && (criteria.originStateCodes.length > 0)
          ? makeLocationObject(criteria.originStateCodesByCountryCode)
          : null,
      preferredLaneId: criteria.preferredLaneId || null,
      specializedEquipmentCode: criteria.specializedEquipmentCode || null,
      teamLoadsOnly: criteria.teamLoadsOnly || null,
      emailNotifications: laneNotification,
      updatedBy: criteria.updatedBy || null,
      updatedDateTime: moment().format(API_DATE_FORMAT),
    }
  });
};
