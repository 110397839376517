export const DialogTitleContainer = {
 display: 'flex',
 gap: '16px',
 alignItems: 'center',
 justifyContent: 'space-between',
 padding: '16px 24px',
};

export const DialogTitle = {
 color: '#212121',
 fontSize: '18px',
 fontWeight: '400',
 lineHeight: '24px',
 letterSpacing: '0.15px',
};

export const DialogCloseButton = {
 color: '#212121',
};

export const DialogBody = {
 color: '#212121',
 fontSize: '14px',
 fontWeight: '400',
 lineHeight: '24px',
 letterSpacing: '0.5px',
};

export const DialogActions = {
 padding: '24px',
};

export const FormIcon = {
 fontSize: '24px',
 marginRight: '8px',
};

export const FormButton = {
 height: '53px',
 width: '50%',
 border: '1px solid #AAAEB0',
};

export const SelectedButton = {
 ...FormButton,
 background: 'rgba(0, 113, 199, 0.08)',
 border: '2px solid #0071C7 !important',
};
