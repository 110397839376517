import { combineEpics } from 'redux-observable';
import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/switchMap';
import * as a from 'shared/offers/redux/offers.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { Offer } from 'shared/models/offers/offer.model';
import { updateOfferStatusFromSignalR } from 'shared/offers/redux/offers.actions';
import { Translation } from 'shared/components/translation/translation.component';
import { ToastManager } from 'shared/components/toast/toast.actions';
import { NotificationActionTypes } from 'store/epics/notifications/notifications.actions';
import { OfferStatus } from '../../enums/offer-status.enum';
import { postSearchOffersAsync } from '@api/offers';
import { APIErrorResponse } from '@app/repositories/errors/api-error-response';
import { offerMapper } from '@shared/offers/offer-mapper';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { t } from 'i18next';

interface Deps {
  repo: AvailableLoadsRepository;
}

async function searchOffersAsyncAdapter(request: SearchOfferRequestJSON): Promise<OfferModelJSON[]> {
 const offersResult = await postSearchOffersAsync(request);
 if (!offersResult.success) {
  throw new APIErrorResponse(offersResult.error.message as unknown as API.ErrorResponse, offersResult.error.code);
 }

 return offersResult.value;
}

type OffersEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, Deps>;

export const fetchOffersEpic: OffersEpic = (action$, state$, { repo }) => {
  const toasts = Container.get(ToastManager);
  const searchRequest = {} as SearchOfferRequestJSON;
  return action$.ofType(a.FETCH_OFFERS)
   .map((): [LDClient] => [Container.get<LDClient>('LD_CLIENT')])
   .map(([ldClient]): [LDFlagSet] => [ldClient.allFlags()])
   .map(([flags]): [boolean] => [
     flags['use-nc-offers'] || false,
    ])
    .mergeMap(([useNcOffers]) => {
     if (useNcOffers) {
      return (
       Observable.from(searchOffersAsyncAdapter(searchRequest))
        .map(result => offerMapper(result))
        .map((offers: Offer[]) => a.fetchOffersSuccess(offers))
        .catch((error: AjaxError) => {
          toasts.error([t('AN_ERROR_OCCURED_ATTEMPTING_TO_LOAD_THE_PAGE')]);
          return Observable.of(a.fetchOffersFailure(error));
         }
        )
      );
     } else {
      return (
       repo.getOffers()
        .map(resultSet => resultSet.results)
        .map((offers: Offer[]) => a.fetchOffersSuccess(offers))
        .catch((error: AjaxError) => {
          toasts.error([t('AN_ERROR_OCCURED_ATTEMPTING_TO_LOAD_THE_PAGE')]);
          return Observable.of(a.fetchOffersFailure(error));
        }
       )
     );
    }
  });
};

export const updateOffersOfferStatusEpic: OffersEpic = (action$, state$) =>
  action$.ofType(NotificationActionTypes.OFFER_RESULT_NOTIFICATION_RECEIVED)
    .map(({ payload }) => {
      const notification = payload as OfferResultNotification;
      switch (notification.offerNegotiationResult) {
       case 0: // Accepted
          return updateOfferStatusFromSignalR({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            offerId: notification.offerId,
            bookOfferId: notification.offerIdGuid,
            currencyCode: notification.currencyCode,
            price: notification.price,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            updatedStatus: OfferStatus.ACCEPTED,
            expirationDateTimeUtc: notification.expirationDateTimeUtc,
          });
        case 1: // Rejected
          return updateOfferStatusFromSignalR({
            offerId: notification.offerId,
            bookOfferId: notification.offerIdGuid,
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: OfferStatus.REJECTED,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            rejectionReason: notification.rejectionReason,
          }
          );
        case 2: // Not Considered
          return updateOfferStatusFromSignalR({
            offerId: notification.offerId,
            bookOfferId: notification.offerIdGuid,
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            updatedStatus: OfferStatus.NOT_CONSIDERED,
          });
        case 3: // Countered
          return updateOfferStatusFromSignalR({
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            updatedStatus: OfferStatus.COUNTERED,
            currencyCode: notification.currencyCode,
            offerId: notification.offerId,
            bookOfferId: notification.offerIdGuid,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            price: notification.price,
            expirationDateTimeUtc: notification.expirationDateTimeUtc,
          });
        default:
          return updateOfferStatusFromSignalR({
            offerId: notification.offerId,
            bookOfferId: notification.offerIdGuid,
            loadNumber: notification.loadNumber,
            offerRequestId: notification.transactionId,
            resultReceivedDate: notification.receivedDate,
            isFinalNegotiation: notification.isFinalNegotiation ?? true,
            updatedStatus: OfferStatus.UNABLE_TO_PROCESS
          });
      }
    }
    );

export const offersEpic = (action$, store) => combineEpics(
  fetchOffersEpic,
  updateOffersOfferStatusEpic
)(action$, store, { repo: Container.get(AvailableLoadsRepository) });
