import React from 'react';

import { Label } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { PageHeader } from 'containers/layout/page-header/page-header.component';
import { useTranslation } from 'react-i18next';

import './insurance-validation.component.scss';

interface OwnProps {
  suppressHeader?: boolean;
  pendingCarrier: CarrierDetail;
  carrier: CarrierDetail;
}

type Props = OwnProps;

export const InsuranceValidationComponent: React.FC<Props> = ({suppressHeader, ...props}) => {
  const carrier = props.pendingCarrier || props.carrier;
  const resources = useResources();
  const { t } = useTranslation();

  if (carrier && (carrier.hasExpiredInsurance() || carrier.hasExpiringInsurance()) &&
  (carrier.typeOfInsurance === 3 || carrier.typeOfInsurance  === 4)) {
    return (
      Boolean(carrier && resources && Object.keys(resources).length) &&
      <div id="insurance-validation-page">
          <div id="insurance-validation">
            {
              !suppressHeader && (carrier.hasExpiredInsurance()
                ? <h3>{t("INSURANCE_EXPIRED")}</h3>
                : <h3>{t("INSURANCE_EXPIRING_SOON")}</h3>)
            }
              <div>
                  <div>
                      <p>
                        {
                          carrier.hasExpiredInsurance()
                            ? t("OUR_RECORDS_INDICATE_THAT_YOUR_CARRIER_LIABILITY_I")
                            : <Translation resource="OUR_RECORDS_INDICATE_THAT_YOUR_CARRIER_LIABILITY2" replacements={[carrier.daysUntilInsuranceExpiration]}/>
                        }
                      </p>
                  </div>
              </div>
              <div>
                  {t("IN_ORDER_TO_CONTINUE_BOOKING_LOADS_HIGHWAY")}
              </div>
              <div>
                  <Label resource="EMAIL">:</Label>{' '}
                  <a href="mailto:insurance@certs.highway.com">insurance@certs.highway.com</a>
              </div>
              <div>
                  {t("PLEASE_ASK_YOUR_INSURANCE_AGENT_TO_LIST_THE_CERTIF")}
              </div>
              <div className="company-address">
                  Highway App, Inc.<br/>
                  5931 Greenville Ave, Unit #5620<br/>
                  Dallas, TX 75206
              </div>
          </div>
      </div>
    );
  } else {
    return (
      Boolean(carrier && resources && Object.keys(resources).length) &&
      <div id="insurance-validation-page">
          <div id="insurance-validation">
            {
              !suppressHeader && (carrier.hasExpiredInsurance()
                ? <PageHeader documentTitle="INSURANCE_EXPIRED" header="INSURANCE_EXPIRED"/>
                : <h3>{t("INSURANCE_EXPIRING_SOON")}</h3>)
            }
              <div>
                  <div>
                      <p>
                        {
                          carrier.hasExpiredInsurance()
                            ? t("INSURANCE_HAS_EXPIRED")
                            : <Translation resource="INSURANCE_WILL_EXPIRE" replacements={[carrier.daysUntilInsuranceExpiration]}/>
                        }
                      </p>
                  </div>
              </div>
              <div>
                  <p>{t("IN_ORDER_TO_CONTINUE_BOOKING_LOADS_FOR_C_H_ROBINSO")}</p>
              </div>
              <div className="company-contact">
                  <div>
                      <Label resource="EMAIL">:</Label>{' '}
                      <a href="mailto:insurance@certs.highway.com">insurance@certs.highway.com</a>
                  </div>
              </div>

              <p>{t("PLEASE_ASK_YOUR_INSURANCE_AGENT_TO_LIST_THE_CERTIF")}</p>

              <div className="company-address">
                  Highway App, Inc.<br/>
                  5931 Greenville Ave, Unit #5620<br/>
                  Dallas, TX 75206
              </div>
          </div>
      </div>
    );
  }
};

interface WrapperProps {
  suppressHeader?: boolean;
}

export const InsuranceValidation: React.FC<WrapperProps> = ({suppressHeader}) => {
  const pendingCarrier = useSelector(state => state.auth.pending.carrier);
  const currentCarrier = useStoredCarrierDetail();
  return <InsuranceValidationComponent pendingCarrier={pendingCarrier} carrier={currentCarrier} suppressHeader={suppressHeader}/>;
};