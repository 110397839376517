/// <reference path="../custom-typings.d.ts" />
/// <reference path="../typings.d.ts" />
import "./styles.scss";

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import 'reflect-metadata';

import 'app/globals/app-tokens';
import 'app/globals/parent-storage';
import 'app/globals/tab-locks';
import 'app/browser/window';

import { store } from 'store';
import { CarrierApp } from 'app/App';
import reportWebVitals from 'reportWebVitals';
import 'app/globals/init-signalr';
import '../i18n';
import { init as initResponseMock } from 'responseMock/mock';
import { mockedResponses } from 'responseMock/mockedResponses/offersConfig';

const root = createRoot(document.getElementById('root'));

// add UseLocalFetchMock=true to .env file to use local fetch mock
if (process.env.UseLocalFetchMock) {
  initResponseMock(mockedResponses);
}

root.render(
  <Provider store={store}>
    <CarrierApp />
  </Provider>
);

reportWebVitals();
serviceWorker.register();
