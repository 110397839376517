import { OfferModelStatus, OfferStatus } from '@shared/enums/offer-status.enum';
import { Offer } from '@shared/models/offers/offer.model';
import { OfferModelType, OfferType } from '@shared/enums/offer-type.enum';
import AvailableLoadOfferRejectionReason from '@shared/enums/available-load-offer-rejection-reason';

export function offerMapper(offers: OfferModelJSON[]): Offer[] {
 if (!offers) {
  return null;
 }
 const mappedOffers = [] as Offer[];
 offers.forEach(offerModel => {
  const offer = {
   offerId: 0,
   bookOfferId: offerModel.bookOfferId,
   offerRequestId: offerModel.bookOfferRequestId,
   loadNumber: Number(offerModel.shipment.shipmentId),
   displayStatus: offerDisplayStatusMapper(offerModel.negotiationResult.statusType, offerModel.type, offerModel.shipment.isShipmentAvailable),
   offerPrice: Number(offerModel.price.price),
   enteredDate: offerModel.enteredDateTimeUtc,
   currencyCode: offerModel.price.currencyCode,
   originLocation: offerLocationMapper(offerModel.shipment.origin),
   destinationLocation: offerLocationMapper(offerModel.shipment.destination),
   offerType: offerTypeMapper(offerModel.type),
   rejectionReason: offerModel.negotiationResult.reason,
   isShipmentAvailable: offerModel.shipment.isShipmentAvailable,
   isShipmentOfferable: offerModel.shipment.isShipmentOfferable,
   isShipmentCounterOfferable: offerModel.shipment.isShipmentCounterOfferable,
   expirationDateTimeUtc: offerModel.expirationDateTimeUtc,
   enteredDateUtc: offerModel.enteredDateTimeUtc,
   offerNote: '',
   acceptedByDataScience: (offerModel.negotiationResult.statusType === OfferModelStatus.ACCEPTED && offerModel.negotiationResult.by === 'DataScience'),
  } as OfferJSON;

  mappedOffers.push(new Offer(offer));
 });

 return mappedOffers;
}

function offerLocationMapper(location: ShipmentLocationModelJSON): LocationJSON {
 const mappedLocation: LocationJSON = {
  city: location.city,
  stateProvinceCode: location.stateCode,
  county: location.county,
  geolocation: null,
  countryCode: location.countryCode,
 };

 return mappedLocation;
}

function offerDisplayStatusMapper(displayStatus: number, type: number, isShipmentAvailable: boolean): number {
 switch (displayStatus) {
  case OfferModelStatus.ACCEPTED:
   if (!isShipmentAvailable) {
    return OfferStatus.NO_LONGER_AVAILABLE;
   }
    return OfferStatus.ACCEPTED;
  case OfferModelStatus.REJECTED:
    return OfferStatus.REJECTED;
  case OfferModelStatus.NOT_CONSIDERED:
    return OfferStatus.PENDING;
  case OfferModelStatus.EXPIRED:
    return OfferStatus.EXPIRED;
  case OfferModelStatus.PENDING:
    if (type === OfferModelType.Load && isShipmentAvailable) {
      return OfferStatus.COUNTERED;
    }
    if (type === OfferModelType.Load && !isShipmentAvailable) {
     return OfferStatus.NO_LONGER_AVAILABLE;
    }
    return OfferStatus.PENDING;
  default:
    return OfferStatus.OPEN;
 }
}

function offerTypeMapper(type: number): number {
 switch (type) {
    case OfferModelType.Truck:
        return OfferType.Truck;
    case OfferModelType.Load:
    default:
        return OfferType.Load;
 }
}
