import { AnyAction, combineReducers } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';
import { NotificationActionTypes } from 'store/epics/notifications/notifications.actions';
import * as a from 'shared/find-loads/redux/find-loads.actions';
import { createPaginationReducer } from 'shared/components/pagination/pagination.reducer';
import { searchHistory, SearchHistoryState } from 'pages/find-loads/search-history/search-history.reducers';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { getMicrosoft } from 'providers/microsoft.provider';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { LoadSummaryCarrierOffer } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { CarrierBook } from 'shared/models/loads/load-books/carrier-book.model';
import { notificationToLoadSummaryOfferMapper } from '@shared/offers/load-summary-offer-mapper';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';
import { OFFER_STATUS } from '@pages/find-loads-ver2/constants';

export interface FindLoadsState {
  searchType: AvailableLoadSearchType;
  results: FindLoadsResultsState;
  error: APIErrorResponse;
  searchHistory: SearchHistoryState;
  searchCriteria?: AvailableLoadSearchCriteriaJSON;
  isMicrosoftLoaded: boolean;
  searchCorrelationId?: string;
}

export interface FindLoadsResultsState {
  loads: AvailableLoadSummary[];
  offers: LoadSummaryCarrierOffer[];
  carrierBooks: CarrierBook[];
  failedBooks: number[];
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
  performedSuggestedLoadSearch: boolean;
  carrierValidationResults?: {};
}

export const searchCriteria = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SET_SEARCH_CRITERIA:
      return action.criteria;
    case a.UNSET_SEARCH_CRITERIA:
      return null;
    default:
      return state;
  }
};

export const loads = (state = null, action) => {
  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      return action.payload.results;
    case a.RESET_AVAILABLE_LOADS:
    case a.UNSET_SEARCH_CRITERIA:
      return null;
    default:
      return state;
  }
};

export const carrierBooks = (state = [], action) => {
  switch (action.type) {
    case a.STORE_NEW_CARRIER_BOOK: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

export const failedBooks = (state = [], action) => {
  switch (action.type) {
    case a.STORE_NEW_FAILED_BOOK: {
      return [...state, action.payload];
    }
    case a.CLEAR_FAILED_BOOK: {
      return [];
    }
    default:
      return state;
  }
};

export const carrierValidationResults = (state = {}, action) => {
  switch (action.type) {
    case a.STORE_CARRIER_VALIDATION_RESULT: {
      let localState = { ...state };
      localState[action.payload.loadNumber] = action.payload.result;
      return localState;
    }
    // clear on new search
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS: {
      return {};
    }
    default:
      return state;
  }
};

export const offers = (state = null, action) => {

  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      const availableLoadSummaries: AvailableLoadSummary[] = action.payload.results;
      const loadSummaryCarrierOffers: LoadSummaryCarrierOffer[] = [];
      for (let i = 0; i < availableLoadSummaries?.length; ++i) {
        const load = availableLoadSummaries[i];
        for (let j = 0; j < load?.carrierOffers?.length; ++j) {
          const carrierOffer = load.carrierOffers[j];
          if (carrierOffer) {
            carrierOffer.loadNumber = load.number;
            loadSummaryCarrierOffers.push(carrierOffer);
          }
        }
      }
      return loadSummaryCarrierOffers;
    case a.RESET_AVAILABLE_LOADS:
    case a.UNSET_SEARCH_CRITERIA:
      return null;
    // Add offer from signal r payload to the state
    case NotificationActionTypes.OFFER_RESULT_NOTIFICATION_RECEIVED: {
      const payload = action.payload as OfferResultNotification;
      const currentState = [...state as LoadSummaryCarrierOffer[]];
      const loadNumber = payload.loadNumber;
      const index = currentState.findIndex(x => x.loadNumber === loadNumber);
      const offer = notificationToLoadSummaryOfferMapper(payload);
      const carrierOffer = {
        loadNumber: loadNumber,
        latestOffer: offer,
        preferredOffer: offer
      } as LoadSummaryCarrierOffer;

      if (index < 0) {
        return [...currentState,
          carrierOffer];
      }
      else {
        currentState[index] = carrierOffer;
        return currentState;
      }
    }
    case a.UPDATE_OFFER_STATUS: {
      if (state == null) {
        return null;
      }
      const updatedState = [...state as LoadSummaryCarrierOffer[]];
      const updateParameter = action.payload as a.OfferStatusUpdateParameter;
      const index = updatedState.findIndex(x => x.loadNumber === updateParameter?.loadNumber);

      if (index < 0) {
        return state;
      }

      updatedState[index] = { ...updatedState[index] } as LoadSummaryCarrierOffer;
      const latestOffer = updatedState[index].latestOffer;
      const preferredOffer = updatedState[index].preferredOffer;

      if (updateParameter.updatedStatus === OFFER_STATUS.UNABLE_TO_PROCESS) {
        updatedState[index].latestOffer.offerStatus = updateParameter.updatedStatus;
        updatedState[index].preferredOffer.offerStatus = updateParameter.updatedStatus;
        return updatedState;
      }

      if (latestOffer
        && latestOffer.offerStatus !== AvailableLoadOfferStatus.EXPIRED
        && (latestOffer.offerId === updateParameter.offerId || latestOffer.bookOfferId === updateParameter.bookOfferId)) {
        updatedState[index].latestOffer.offerStatus = updateParameter.updatedStatus;
      }

      if (preferredOffer
        && preferredOffer.offerStatus !== AvailableLoadOfferStatus.EXPIRED
        && (preferredOffer.offerId === updateParameter.offerId || preferredOffer.bookOfferId === updateParameter.bookOfferId)) {
        updatedState[index].preferredOffer.offerStatus = updateParameter.updatedStatus;
      }

      return updatedState;
    }
    case a.STORE_NEW_OFFER:
      const { loadNumber, offer } = action.payload as a.StoreNewOfferParameter;
      const updatedState = [...state as LoadSummaryCarrierOffer[]];
      const index = updatedState.findIndex(x => x.loadNumber === loadNumber);

      const carrierOffer = {
        loadNumber: loadNumber,
        latestOffer: offer,
        preferredOffer: offer
      } as LoadSummaryCarrierOffer;

      if (index < 0) {
        return [...state,
          carrierOffer];
      }
      else {
        updatedState[index] = carrierOffer;
        return updatedState;
      }

    case a.STORE_RELOAD_OFFERS: {
      const updatedState = [...state as LoadSummaryCarrierOffer[]];

      for (let i = 0; i < action.payload?.length; ++i) {
        const carrierOffer = action.payload[i];
        const index = updatedState.findIndex(x => x.loadNumber === carrierOffer.loadNumber);
        if (index >= 0) {
          updatedState[index] = carrierOffer;
        } else {
          updatedState.push(carrierOffer);
        }
      }
      return updatedState;
    }
    default:
      return state;
  }
};

export const performedSuggestedLoadSearch = (state = false, action) => {
  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      return action.payload.performedSuggestedLoadSearch ?? false;
    default:
      return state;
  }
};

export const searchType = (state = AvailableLoadSearchType.SINGLE, action) => {
  switch (action.type) {
    case a.SET_SEARCH_TYPE:
      return action.value;
    default:
      return state;
  }
};

export const searchCorrelationId = (state = null, action) => {
  switch (action.type) {
    case a.SET_SEARCH_CORRELATION_ID:
      return action.searchCorrelationId;
    default:
      return state;
  }
};

export const error = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.SEARCH_AVAILABLE_LOADS_FAILURE:
      return action.payload;
    case a.RESET_ERROR_MESSAGE:
    case LOCATION_CHANGE:
    case a.SEARCH_AVAILABLE_LOADS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const isMicrosoftLoaded = (state = Boolean(getMicrosoft()), action: AnyAction) => {
  switch (action.type) {
    case a.MICROSOFT_LOAD_SUCCESS:
      return action.isMicrosoftLoaded;
    default:
      return state;
  }
};

export const findLoadsReducer = combineReducers<FindLoadsState>({
  searchType,
  error,
  results: combineReducers({
    loads,
    offers,
    carrierBooks: carrierBooks,
    failedBooks: failedBooks,
    pagination: createPaginationReducer('find-loads-results'),
    performedSuggestedLoadSearch: performedSuggestedLoadSearch,
    carrierValidationResults: carrierValidationResults
  }),
  searchHistory,
  searchCriteria,
  isMicrosoftLoaded,
  searchCorrelationId
});
