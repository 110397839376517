import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { getUser, getMembership, putMembership } from 'api/userManagement';
import { BreadcrumbsComponent } from './BreadcrumbsComponent';
import { CompanyInformation } from './CompanyInformation';
import { MembershipStatus } from 'features/account-settings';
import { useSelector } from '@app/hooks/store/use-selector.hook';
import { ManageUsersViews } from './ManageUsers';
import { useSnackbar, SeverityType } from '@chr/eds-react';
import * as Styles from 'features/account-settings/manage-users/styles';

export interface EditUserProps {
    userId: string;
    setView: Dispatch<SetStateAction<symbol>>;
}

export const EditUser = ({ userId, setView }: EditUserProps) => {
    const [editUserInfo, setEditUserInfo] = useState<MembershipsUser>(null);
    const [isAdmin, setIsAdmin] = useState<Boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [membership, setMembership] = useState<Membership>(null);
    const [membershipStatus, setMembershipStatus] = useState<MembershipStatus>(null);
    const [role, setRole] = useState('');

	const snackbar = useSnackbar();

    const carrier = useSelector(state => state.auth?.carrier);
    const activeMembership = useSelector(state => state.userManagement?.activeMembership);
    const { t } = useTranslation();

	const handleSuccess = () => {
		setView(ManageUsersViews.MANAGE_USERS);
		snackbar.enqueueSnackbar({
		  id: 'edit-user-success',
		  message: t('USER_INFORMATION_SAVED'),
		  severity: 'success' as SeverityType
		});
	  }
	  const handleFailure = () => {
		snackbar.enqueueSnackbar({
		  id: 'edit-user-failure',
		  message: t('FALLBACK_SOMETHING_WENT_WRONG'),
		  severity: 'error' as SeverityType,
		});
	  }

    useEffect(() => {
        setIsLoading(true);
        getUser(userId)
            .then((res: GetUserResponse) => setEditUserInfo(res.user))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }, [userId]);

    useEffect(() => {
        setIsLoading(true);
        getMembership(activeMembership.organizationId, userId)
            .then((res: MembershipResponse) => setMembership(res))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }, [userId]);

    const handleMembershipUpdate = () => {
        setIsSaving(true);
        putMembership(userId, membership.id, {
            isAdmin: isAdmin,
            roles: [role],
            status: membershipStatus,
        })
            .then(() => handleSuccess())
            .catch(err => handleFailure())
            .finally(() => setIsSaving(false));
    };

    return (
        <Box>
            <BreadcrumbsComponent setView={setView} view={t('EDIT_USER')} />
            <Typography variant="heading200" sx={Styles.AddUserHeader}>
                {t('EDIT_USER')}
            </Typography>
            <Box sx={{ maxWidth: '1102px', p: '24px 40px', mt: '10px', bgcolor: '#FFFFFF' }}>
                <Typography variant="h4">{t('USER_INFORMATION')}</Typography>
                <Box sx={{ width: '100%', marginTop: '20px' }}>
                    <UserInfoRow
                        isLoading={isLoading}
                        infoKey={t('NAME')}
                        value={`${editUserInfo?.firstName} ${editUserInfo?.lastName}`}
                        styles={{ borderRadius: '8px 8px 0 0' }}
                    />
                    <UserInfoRow isLoading={isLoading} infoKey={t('EMAIL')} value={editUserInfo?.email} />
                    <UserInfoRow
                        isLoading={isLoading}
                        infoKey={t('PHONE')}
                        value={editUserInfo?.attributes?.phone}
                        styles={{ borderRadius: '0 0 8px 8px' }}
                    />
                </Box>
            </Box>
            <CompanyInformation
                carrier={carrier}
                membership={membership}
                isSaving={isSaving}
                isAdmin={isAdmin}
                setIsAdmin={setIsAdmin}
                membershipStatus={membershipStatus}
                setMembershipStatus={setMembershipStatus}
                role={role}
                setRole={setRole}
                userData={{ ...editUserInfo, userId }}
                setView={setView}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px', maxWidth: '1102px' }}>
                <Button disabled={isSaving} variant="contained" sx={{ marginLeft: '10px', width: '162px' }} onClick={handleMembershipUpdate}>
                    {t('SAVE')}
                </Button>
            </Box>
        </Box>
    );
};

type UserInfoRowProps = {
    infoKey: string;
    value?: string;
    styles?: { [k: string]: string };
    isLoading: boolean;
};

const UserInfoRow = ({ infoKey, value, isLoading, styles = {} }: UserInfoRowProps) => {
    const boxStyles = {
        ...styles,
        display: 'flex',
        justifyContent: 'start',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '16px',
    };

    const Loading = <Skeleton animation="wave" width="20%" sx={{ m: '0 10px' }} />;

    return (
        <Box sx={boxStyles}>
            {isLoading ? Loading : <Box sx={{ width: '100px', fontWeight: 'bold' }}>{infoKey}</Box>}
            {isLoading ? Loading : <Box>{value}</Box>}
        </Box>
    );
};
