import { Trans, useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { MuiOtpInput } from 'mui-one-time-password-input';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import * as Styles from 'features/account-settings/profile-settings/styles/ChangePhoneNumberDialog.styles';

interface BodyProps {
 phoneNumber: string;
 otpValue: string;
 hasError: boolean;
 onOtpChange: (value: string) => void;
}

interface ActionsProps {
 isLoading: boolean;
 verifyDisabled: boolean;
 resendDisabled: boolean;
 countdown: number;
 onResendSMS: () => void;
 onVerifySMS: () => void;
}

const Body = ({ phoneNumber, otpValue, onOtpChange, hasError }: BodyProps) => {
 const { t } = useTranslation();

 const verifyIfIsNumeric = value => {
  return /^\d+$/.test(value); // returns true if value is numeric
 };

 return (
  <Box
   sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
    gap: '24px',
    width: { sm: '70%' },
    margin: '0 auto',
   }}
  >
   <Box
    sx={{
     width: 130,
     height: 130,
     borderRadius: '50%',
     backgroundColor: '#D2E9F7',
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center',
    }}
   >
    <LocalPhoneOutlinedIcon sx={{ color: '#0071C7', fontSize: '64px' }} />
   </Box>
   <Typography variant="body1" sx={{ ...Styles.DialogBody, fontSize: '18px' }}>
    <strong>{t('VERIFY_YOUR_PHONE_NUMBER')}</strong>
   </Typography>
   <Typography variant="body1" sx={{ ...Styles.DialogBody, textAlign: 'center' }}>
    <Trans i18nKey="CODE_HAS_BEEN_SENT" components={[<br />, <strong>{formatPhoneNumberIntl(phoneNumber)}</strong>, <strong>SMS</strong>]} />
   </Typography>
   <Box sx={{ width: '100%' }}>
    <MuiOtpInput
     value={otpValue}
     onChange={onOtpChange}
     length={6}
     validateChar={verifyIfIsNumeric}
     gap="8px"
     sx={
      hasError
       ? {
          '& fieldset': {
           borderColor: '#D90202',
          },
         }
       : {}
     }
     autoFocus
    />
    {hasError && (
     <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <ErrorOutlineOutlinedIcon sx={{ fontSize: '14px', color: '#8E0000' }} />
      <Typography variant="body1" sx={{ ...Styles.DialogBody, fontSize: '12px', color: '#8E0000' }}>
       {t('INCORRECT_OTP')}
      </Typography>
     </Box>
    )}
   </Box>
  </Box>
 );
};

const Actions = ({ isLoading, verifyDisabled, countdown, resendDisabled, onResendSMS, onVerifySMS }: ActionsProps) => {
 const { t } = useTranslation();

 return (
  <>
   <Button
    sx={{ height: '56px' }}
    variant="outlined"
    color="primary"
    onClick={onResendSMS}
    startIcon={isLoading && <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.28)' }} size={18} />}
    disabled={isLoading || resendDisabled}
    fullWidth
   >
    {resendDisabled ? `${t('RESEND_SMS')} (${countdown}s)` : t('RESEND_SMS')}
   </Button>
   <Button
    sx={{ height: '56px' }}
    color="primary"
    variant="contained"
    onClick={onVerifySMS}
    startIcon={isLoading && <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.28)' }} size={18} />}
    disabled={isLoading || verifyDisabled}
    fullWidth
   >
    {t('VERIFY')}
   </Button>
  </>
 );
};

export const ChangePhoneNumberDialogStepTwo = {
 Body,
 Actions,
};
