import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { PhoneNumberType } from 'features/registration';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface PhoneNumberWithTypeProps {
 phone: string;
 phoneType: PhoneNumberType;
}

export const PhoneNumberWithType = ({ phone, phoneType = PhoneNumberType.MOBILE }: PhoneNumberWithTypeProps) => {
 const { t } = useTranslation();
 const parsedPhoneNumber = phone ? parsePhoneNumber(phone) : null;

 if (!parsedPhoneNumber) {
  return (
   <Typography variant="body2" component="span">
    {t('EMPTY_PHONE_NUMBER')}
   </Typography>
  );
 }

 const phoneCountryCode = parsedPhoneNumber.country;
 const FlagIcon = phoneCountryCode ? flags[phoneCountryCode] : null;

 return (
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
   <Typography
    variant="subtitle2"
    component="span"
    sx={{
     borderRadius: '4px',
     border: '1px solid rgba(0, 0, 0, 0.15)',
     padding: '0px 4px',
     marginRight: '8px',
    }}
   >
    {t(phoneType === PhoneNumberType.MOBILE ? 'MOBILE' : 'BUSINESS')}
   </Typography>
   {FlagIcon && (
    <Box sx={{ width: '21px', height: '18px', marginRight: '8px' }}>
     <FlagIcon title={`${phoneCountryCode} country flag`} />
    </Box>
   )}
   <Typography variant="subtitle2" component="span">
    {formatPhoneNumberIntl(phone)}
   </Typography>
  </Box>
 );
};
