import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { FinancialsRepository } from 'app/repositories/financials.repository';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import {
  FETCH_CASH_ADVANCE_OPTIONS,
  FetchCashAdvanceOptionsAction,
  fetchCashAdvanceOptionsSuccess,
  fetchCashAdvanceOptionsSuccessV3,
} from './cash-advance-options.actions';
import { SetLoadDetailsAction, SET_LOAD_DETAILS } from '../../../load-details.actions';
import { LoadError, LoadNotFound } from 'shared/models/loads/extended-load.model';
import { LDClient } from 'launchdarkly-js-client-sdk';

export interface EpicDependencies {
  repo: FinancialsRepository;
}

type CashAdvanceOptionsEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, EpicDependencies>;

export const fetchCashAdvanceOptionsEpic: CashAdvanceOptionsEpic = (action$, state$, { repo }) =>
  action$
    .ofType<FetchCashAdvanceOptionsAction | SetLoadDetailsAction>(SET_LOAD_DETAILS, FETCH_CASH_ADVANCE_OPTIONS)
    .filter(action => Boolean(action.load))
    .filter(action => Boolean(action.load.number))
    .filter(action => !(action.load instanceof LoadError))
    .filter(action => !(action.load instanceof LoadNotFound))
    .switchMap(action => {
      const flag = Container.get<LDClient>('LD_CLIENT').allFlags().enableTPayforCashAdvanceWeb;
      if (flag) {
        return repo
          .getCashAdvanceOptionsV3(action.load.number, action.load.bookSequenceNumber)
          .catch(() => Observable.empty())
          .map(fetchCashAdvanceOptionsSuccessV3);
      } else {
        return repo
          .getCashAdvanceOptions(action.load.number, action.load.bookSequenceNumber)
          .catch(() => Observable.empty())
          .map(fetchCashAdvanceOptionsSuccess);
      }
    });

export const cashAdvanceOptionsEpic = (action$, store) =>
  combineEpics(fetchCashAdvanceOptionsEpic)(action$, store, { repo: Container.get(FinancialsRepository) });
