import { useEffect } from 'react';
import {
 SelectCarrierDialog,
 needsToUpdateProfile
} from '@features/permissions';
import { useDispatch } from 'react-redux';
import { useSelector } from '@app/hooks/store/use-selector.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuthorization } from 'features/permissions';
import { AppRoute } from 'app/routesEnum';
import { push } from 'connected-react-router';

export default function AuthorizationContainer({ children, t }: any) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(s => s.auth);
  const startPage = useSelector(s => s.userManagement?.auth?.user?.properties?.startPage);
  const activeMembership = useSelector(s => s.userManagement?.activeMembership);
  const path = window.location.pathname;
  const isAuthorized = useAuthorization(path as AppRoute);
  const isStartPageAuthorized = startPage ? useAuthorization(AppRoute[startPage]) : false;
  const needsToUpdate = needsToUpdateProfile();

  const userId = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims?.userId;
  const { enableUserManagement } = useFlags();
  const showDialog = userId && enableUserManagement;
  
  // if user switches active membership and is no longer authorized for page they are on redirect to start page if available, then settings
  useEffect(() => {
	if (enableUserManagement && !isAuthorized) {
	  isStartPageAuthorized ? dispatch(push(AppRoute[startPage])) : dispatch(push(AppRoute.ACCOUNT_SETTINGS));
	}
  }, [isAuthorized, activeMembership]);

  useEffect(() => {
    if(enableUserManagement && needsToUpdate && isAuthenticated) {
      dispatch(push(AppRoute.SET_UP_USER));
    }
  }, [enableUserManagement, needsToUpdate, isAuthenticated]);

  return (
	<>
	  {showDialog && <SelectCarrierDialog t={t} />}
	  {children}
	</>
  );
}
