import { Dispatch, SetStateAction } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import * as Styles from 'features/account-settings/financial-settings/styles/TriumphSignupDialogStyles';

export interface TriumphSignupDialogProps {
  showDialog: boolean;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  userId: number;
  contactId: number;
}

export const TriumphSignupDialog = ({ showDialog, setShowDialog, userId, contactId }: TriumphSignupDialogProps) => {
  const { t } = useTranslation();

  // populate links with proper values
  const hostname = window?.location?.hostname;
  const isProd = hostname?.startsWith('www.navispherecarrier') || hostname?.startsWith('navispherecarrier');
  const getStartedLink = isProd ?
    `https://www.chrobinson.com/refr.aspx?dest=https%3A%2F%2Fapply.FactoringByDelta.com%3Frd%3D&mdmcontactdetailid=${contactId}&navcarrierid=${userId}&utm_medium=product&utm_source=navcarrierweb&utm_campaign=car-faas-settings&utm_content=cta_get_started`
    : `https://dev.chrobinson.com/refr.aspx?dest=https%3A%2F%2Fapply.FactoringByDelta.com%3Frd%3D&mdmcontactdetailid=${contactId}&navcarrierid=${userId}&utm_medium=product&utm_source=navcarrierweb&utm_campaign=car-faas-settings&utm_content=cta_get_started`

  return (
    <Dialog
      open={showDialog}
      maxWidth='lg'
      PaperProps={{ sx: Styles.Dialog }}
      data-testid="triumph-signup-dialog"
    >
      <Box sx={Styles.ContentContainer}>
        <Box sx={Styles.HeaderContainer}>
          <Typography data-testid="triumph-signup-header" variant="subtitle1">
            {t('YOU_ARE_LEAVING_NAVISPHERE_CARRIER')}
          </Typography>
          <IconButton onClick={() => setShowDialog(false)} sx={{ color: 'black' }} data-testid={'close-btn'}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="body1" data-testid="triumph-signup-body">
          {t('YOU_ARE_GOING_TO_TRIUMPH')}
        </Typography>
        <Stack
          spacing={1}
          direction="row"
          sx={Styles.ButtonBay}
        >
          <Button
            variant='outlined'
            onClick={() => setShowDialog(false)}
            data-testid="cancel-btn"
          >
            {t('CANCEL')}
          </Button>
          <Button
            variant='contained'
            onClick={() => window.open(getStartedLink, '_blank')}
          >
            {t('GO_TO_APPLY')}
          </Button>
        </Stack>
      </Box>
	</Dialog>
  );
}