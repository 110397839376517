import { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useTranslation } from 'react-i18next';
import { AddUser, DataTable, EditUser } from 'features/account-settings';
import * as Styles from 'features/account-settings/manage-users/styles';

export const MANAGE_USERS_SECTION = 'manage-users';

export const ManageUsersViews = Object.freeze({
  MANAGE_USERS: Symbol('manage-users'),
  ADD_USER: Symbol('add-user'),
  EDIT_USER: Symbol('edit-user'),
});

export const ManageUsers = () => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState(null);
  const [view, setView] = useState(ManageUsersViews.MANAGE_USERS);

  const isManageUsers = view === ManageUsersViews.MANAGE_USERS;
  const isAddUser = view === ManageUsersViews.ADD_USER;
  const isEditUser = view === ManageUsersViews.EDIT_USER;

  const handleEditUserOnClick = useCallback((userId: string) => {
    setUserId(userId);
    setView(ManageUsersViews.EDIT_USER);
  }, []); 

  return (
    <>
      {isManageUsers && (
        <>
          <Box sx={Styles.HeaderContainer}>
            <Typography variant="heading200">{t('MANAGE_USERS')}</Typography>
            <Button variant="outlined" startIcon={<PersonAddIcon />} onClick={() => setView(ManageUsersViews.ADD_USER)}>
              {t('ADD_USER')}
            </Button>
          </Box>
          <Box sx={Styles.TableContainer}>
            <Typography sx={Styles.MyUsersHeader} variant="heading300">
              {t('MY_USERS')}
            </Typography>
            <DataTable onEditClick={handleEditUserOnClick}/>
          </Box>
        </>
      )}
      {isAddUser && <AddUser setView={setView} />}
      {isEditUser && <EditUser userId={userId} setView={setView} />}
    </>
  );
};
