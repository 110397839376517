import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvitationAction } from 'features/invitation';
import { activateMembership } from 'api/userManagement';
import { useBodyClass } from 'features/registration';
import { SupportSection } from 'features/invitation';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { ConstructionBarrierIcon } from 'assets/icons';

import * as Styles from 'features/invitation/styles';

export const ExtendInvitation = () => {
 useBodyClass('body-background-color');

 const { t } = useTranslation();

 const [isLoading, setIsLoading] = useState(true);
 const [hasError, setHasError] = useState(false);

 const query = new URLSearchParams(window?.location.search);

 const handleAcceptExtendInvitation = async () => {
  const token = query.get('token');

  if (!token) {
   setHasError(true);
   setIsLoading(false);

   return;
  }

  try {
   await activateMembership({ token, action: InvitationAction.ACCEPT });
  } catch (error) {
   setHasError(true);
  } finally {
   setIsLoading(false);
  }
 };

 useEffect(() => {
  handleAcceptExtendInvitation();
 }, []);

 if (isLoading) {
  return (
   <Box sx={Styles.Container}>
    <Box sx={Styles.Card} alignItems="center">
     <Skeleton variant="rectangular" width="104px" height="104px" />
     <Skeleton animation="wave" variant="rounded" width="90%" />
     <Skeleton animation="wave" variant="rounded" width="90%" />
    </Box>
   </Box>
  );
 }

 if (hasError) {
  return (
   <Box sx={Styles.Container}>
    <Box sx={Styles.Card}>
     <Box sx={Styles.ImageWrapper}>
      <ConstructionBarrierIcon alt={t('CONSTRUCTION_BARRIER_ICON')} />
     </Box>
     <Typography variant="body100" sx={Styles.Paragraph} textAlign="center">
      {t('ERROR_EXTENDING_INVITATION')}
     </Typography>
    </Box>
    <SupportSection />
   </Box>
  );
 }

 return (
  <Box sx={Styles.Container}>
   <Box sx={Styles.Card}>
    <Box sx={Styles.ImageWrapper}>
     <img src="assets/images/extend-invitation.svg" width="104px" height="104px" />
    </Box>
    <Typography variant="heading100" sx={Styles.Header} textAlign="center">
     {t('INVITATION_SENT')}
    </Typography>
    <Typography variant="body100" sx={Styles.Paragraph} textAlign="center">
     {t('INVITATION_SUCCESSFULLY_SENT')}
    </Typography>
   </Box>
   <SupportSection />
  </Box>
 );
};
