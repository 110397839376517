import React from 'react';
import { useEffect, useMemo, useRef, ChangeEvent, useState } from 'react';

import { DataTable } from 'shared/components/data-table/data-table.component';
import { CardView, GridView } from 'shared/components/data-table/data-table.lazy';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Button } from 'shared/components/elements/button.component';
import { usePagination } from 'shared/components/data-table/use-data-table.hooks';
import { Pagination } from 'shared/components/pagination/pagination.component';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { DataLabelPair } from 'shared/components/formatters/data-label-pair.component';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { Contact } from 'shared/models/contacts/contact.model';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { useModal } from 'app/hooks/use-modal.hook';
import { DRIVER_MODAL, DriverModal, DELETE_DRIVER_MODAL, DeleteDriverModal } from 'features/account-settings';

import '../styles/manage-drivers.component.scss';

export const MANAGE_DRIVERS_SECTION = 'manage-drivers';

export const ManageDrivers = () => {
  const [pagination, onPagination] = usePagination({pageNumber: 1, pageSize: 25});
  const start = (pagination.pageNumber - 1) * pagination.pageSize;
  const end = pagination.pageNumber * pagination.pageSize;
  const carrier = useStoredCarrierDetail();
  const repo = useRepository(CarriersRepository);
  const [allDrivers, refreshAllDrivers] = useAjaxResponse(() => repo.getDriverContacts(carrier?.carrierCode, true).map(drivers => drivers.filter(driver => driver.isEditable)), [carrier?.carrierCode], {initialValue: []});
  const [showAddEditDriverModal] = useModal<{}>(DRIVER_MODAL);
  const [showDeleteDriverModal] = useModal<{drivers: Contact[]}>(DELETE_DRIVER_MODAL);
  const [selectedDrivers, setSelectedDrivers] = useState<Contact[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const selectAllCheckboxRef = useRef<HTMLInputElement>(null);
  const manageDriversSection = useRef(null);
  const { t } = useTranslation();

  const showDriverModal = useCallback((driver) => {
    showAddEditDriverModal({driver});
  }, [showAddEditDriverModal]);

  const showDeleteModal = useCallback((passedDrivers: Contact[]) => {
    showDeleteDriverModal({drivers: passedDrivers});
  }, [showDeleteDriverModal]);

  const pagedDriverResults = useMemo(
    () =>
      allDrivers
        .slice(start, end),
    [allDrivers, start, end]
  );

  const setIsCheckboxIndeterminate = useCallback((isIndeterminate) => {
    if (selectAllCheckboxRef) {
      selectAllCheckboxRef.current.indeterminate = isIndeterminate;
    }
  }, []);

  const onSelect = useCallback((event: ChangeEvent<HTMLInputElement>, selectedDriver: Contact) => {
    const selectedIds = selectedDrivers.map((driver) => driver.id);
    const newSelectedDrivers = selectedIds.includes(selectedDriver.id) ? selectedDrivers.filter(driver => driver.id !== selectedDriver.id) : [...selectedDrivers, selectedDriver];
    // Some drivers are selected
    if (allDrivers.length > newSelectedDrivers.length && newSelectedDrivers.length > 0) {
      setIsAllSelected(false);
      setIsCheckboxIndeterminate(true);
    }
    // All drivers are selected
    if (allDrivers.length === newSelectedDrivers.length && newSelectedDrivers.length > 0) {
      setIsAllSelected(true);
      setIsCheckboxIndeterminate(false);
    }
    // No drivers are selected
    if (newSelectedDrivers.length === 0) {
      setIsAllSelected(false);
      setIsCheckboxIndeterminate(false);
    }
    setSelectedDrivers(newSelectedDrivers);
  }, [selectedDrivers, allDrivers]);

  const selectAll = useCallback(() => {
    if (isAllSelected) {
      setSelectedDrivers([]);
      if (selectAllCheckboxRef.current) {
        selectAllCheckboxRef.current.indeterminate = false;
      }
      setIsAllSelected(false);
    } else {
      setSelectedDrivers(allDrivers);
      if (selectAllCheckboxRef.current) {
        selectAllCheckboxRef.current.indeterminate = false;
      }
      setIsAllSelected(true);
    }
  }, [isAllSelected, selectAllCheckboxRef.current, allDrivers]);

  const renderGridRow = useCallback((driver) => {
      const selectedIds = selectedDrivers.map((singleDriver) => singleDriver.id);
      const isSelected = selectedIds.includes(driver.id);

      return <tr key={`driver-row-${driver.id}`}>
        <td className="checkbox-column text-center">
          <label htmlFor={`select-row-${driver.id}`} className="visually-hidden">{t("SELECT_FOR_DELETION")}</label>
          <input
            id={`select-row-${driver.id}`}
            type="checkbox"
            checked={isSelected}
            onChange={(e) => onSelect(e, driver)}
          />
        </td>
        <td>
          <div className="flex flex-align-items-center">
            <svg className="space-outer-right-md user-icon" aria-hidden="true" viewBox="0 0 20 20" width="26px" height="26px">
              <title>{t("USER")}</title>
              <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,3 C11.66,3 13,4.34 13,6 C13,7.66 11.66,9 10,9 C8.34,9 7, 7.66 7,6 C7,4.34 8.34,3 10,3 Z M10,17.2 C7.5,17.2 5.29,15.92 4,13.98 C4.03,11.99 8,10.9 10,10.9 C11.99,10.9 15.97,11.99 16,13.98 C14.71,15.92 12.5,17.2 10,17.2 Z" id="path-1"/>
            </svg>
            <span>{driver.firstName} {driver.lastName}</span>
          </div>
        </td>
        <td>{driver.defaultPhone.number}</td>
        <td>{driver.defaultEmail.emailAddress}</td>
        <td className="text-right">
          <Button
            btnDefault
            id="js-edit-driver-button"
            track
            onClick={() => showDriverModal(driver)}
          >
            {t("EDIT")}
          </Button>
          <Button
            className="space-outer-left-sm"
            btnDefault
            id="js-delete-single-driver-button"
            track
            onClick={() => showDeleteModal([driver])}
          >
            {t("DELETE")}
            <span className="visually-hidden">{t("THIS_DRIVER")}</span>
          </Button>
        </td>
      </tr>;
    },
    [selectedDrivers],
  );

  const renderCard = useCallback((driver) => {
      return<div key={`driver-card-${driver.id}`} className="ns-card shadow flow-card">
        <ErrorBoundary className="space-outer-top-xl space-outer-bottom-xl space-outer-left-lg space-outer-right-lg">
          <div className="card-content">
            <svg className="space-outer-right-md user-icon" aria-hidden="true" viewBox="0 0 20 20" width="26px" height="26px">
              <title>{t("USER")}</title>
              <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,3 C11.66,3 13,4.34 13,6 C13,7.66 11.66,9 10,9 C8.34,9 7, 7.66 7,6 C7,4.34 8.34,3 10,3 Z M10,17.2 C7.5,17.2 5.29,15.92 4,13.98 C4.03,11.99 8,10.9 10,10.9 C11.99,10.9 15.97,11.99 16,13.98 C14.71,15.92 12.5,17.2 10,17.2 Z" id="path-1"/>
            </svg>
            <div className="card-content-info">
              <span>{driver.firstName} {driver.lastName}</span>
              <DataLabelPair isSensitive={true} label="PHONE_NUMBER" data={driver.defaultPhone.number}/>
              <DataLabelPair isSensitive={true} className="space-outer-top-md" label="EMAIL_ADDRESS" data={driver.defaultEmail.emailAddress}/>
            </div>
          </div>
          <div className="footer">
            <div className="button-bay">
              <Button id={`edit-driver-button-${driver.id}`} track btnDefault onClick={() => showDriverModal(driver)}>{t("EDIT")}</Button>
              <Button id={`delete-driver-button-${driver.id}`} track className="space-outer-top-sm" btnDefault onClick={() => showDeleteModal([driver])}>
                {t("DELETE")}
                <span className="visually-hidden">{t("THIS_DRIVER")}</span>
              </Button>
            </div>
          </div>
        </ErrorBoundary>
      </div>;
    },
    [],
  );

  if (allDrivers.length === 0) {
    return <div ref={manageDriversSection} className="manage-drivers ns-card shadow content-card space-outer-top-md">
      <div className="flex flex-space-between flex-wrap">
        <h2>{t("DRIVERS")}</h2>
        <Button
          btnPrimary
          id="add-driver-button"
          track
          onClick={() => showDriverModal(null)}
        >
          {t("ADD_A_DRIVER")}
        </Button>
      </div>
      <p className="js-no-drivers-message space-outer-top-md">{t("YOU_HAVE_NO_DRIVERS_AT_THIS_TIME")}</p>
      <DriverModal onSuccess={refreshAllDrivers}/>
    </div>;
  }

  return (
    <div ref={manageDriversSection} className="manage-drivers ns-card shadow content-card space-outer-top-md">
      <h2>{t("DRIVERS")}</h2>
      <DataTable
        items={pagedDriverResults}
        totalRecords={allDrivers.length}
        hideControls
        dataTableButton={
          [
            <Button
              className="hidden-sm hidden-xs"
              btnDefault
              id="js-delete-multiple-drivers-button"
              track
              disabled={Boolean(selectedDrivers.length < 1)}
              onClick={() => showDeleteModal(selectedDrivers)}
            >
              {t("DELETE")}
              <span className="visually-hidden">{t("ALL_SELECTED_DRIVERS")}</span>
            </Button>,
            <Button
              btnPrimary
              id="add-driver-button"
              track
              onClick={() => showDriverModal(null)}
            >
              {t("ADD_A_DRIVER")}
            </Button>
          ]
        }
        pagination={pagination}
        paginationComponent={
          <Pagination
            totalItems={allDrivers.length}
            values={pagination}
            onPagination={onPagination}
          />
        }
      >
        <GridView
          scrollable
          headerRow={
            <tr>
              {/*Checkbox is a td element to prevent screen readers treating it as a table header*/}
              <td className="checkbox-column text-center">
                <label htmlFor="select-all" className="visually-hidden">{t("SELECT_ALL_FOR_DELETION")}</label>
                <input
                  ref={selectAllCheckboxRef}
                  id="select-all"
                  type="checkbox"
                  className={selectedDrivers.length > 0 && !isAllSelected ? 'some-selected' : ''}
                  checked={selectedDrivers.length > 0}
                  onChange={selectAll}
                />
              </td>
              <th scope="col">{t("NAME")}</th>
              <th scope="col">{t("PHONE_NUMBER")}</th>
              <th scope="col">{t("EMAIL_ADDRESS")}</th>
              <th scope="col"><span className="visually-hidden">{t("ACTIONS")}</span></th>
            </tr>
          }
          renderBodyRow={renderGridRow}
        />
        <CardView renderCard={renderCard}/>
      </DataTable>
      <DriverModal onSuccess={refreshAllDrivers}/>
      <DeleteDriverModal onSuccess={refreshAllDrivers}/>
    </div>
  );
};
